import React, { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import { IssueTransitionTypes } from 'constants/index'
import {
  useUpdateClientIssues,
  useClientIssuesFetcher,
  useClientIssueCategoriesFetcher,
} from 'apis'

export default function useCRUDIssue(vendorId, issues, onIssuesTransitioned) {
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()
  const { mutateAsync: updateIssues } = useUpdateClientIssues()
  const { createIssue } = useCreateUpdateIssue()

  const handleCreateIssue = useCallback(() => {
    createIssue({
      vendorId,
      onIssueCreated: () =>
        queryClient.invalidateQueries(useClientIssuesFetcher.queryKey()),
      onIssueCategoryCreated: () =>
        queryClient.invalidateQueries(
          useClientIssueCategoriesFetcher.queryKey(),
        ),
    })
  }, [vendorId, queryClient])

  const transitionIssues = useCallback(
    (ids, state, onSuccess) => {
      const loaderId = showLoader()
      const data = {
        issues: ids.map((id) => ({ id, transition: state })),
      }
      return updateIssues({ data })
        .then(() => {
          if (onSuccess) {
            onSuccess()
          }
          if (onIssuesTransitioned) {
            onIssuesTransitioned(ids, state)
          }
        })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    },
    [updateIssues, onIssuesTransitioned],
  )

  const archiveIssues = useCallback(
    (ids, onSuccess) =>
      transitionIssues(ids, IssueTransitionTypes.ARCHIVE, onSuccess),
    [transitionIssues],
  )

  const sendIssues = useCallback(
    (ids, onSuccess) =>
      transitionIssues(ids, IssueTransitionTypes.OPEN, onSuccess),
    [transitionIssues],
  )

  const resendIssues = useCallback(
    (ids, onSuccess) =>
      transitionIssues(ids, IssueTransitionTypes.RESEND, onSuccess),
    [transitionIssues],
  )

  const deleteIssues = useCallback(
    (ids, onSuccess) => {
      const tmpSet = issues?.filter((e) => ids.includes(e.id))
      openConfirm({
        title: 'Are you sure you want to delete the following issues?',
        body: (
          <ul>
            {tmpSet?.map((issue) => (
              <li key={issue.id}>{issue.summary}</li>
            ))}
          </ul>
        ),
        confirmText: 'Yes, delete these issues',
        cancelText: 'Cancel',
        onConfirm: () => {
          transitionIssues(ids, IssueTransitionTypes.DELETE, onSuccess)
        },
      })
    },
    [transitionIssues, issues, openConfirm],
  )

  const sendIssue = useCallback((id) => sendIssues([id]), [sendIssues])

  return {
    createIssue: handleCreateIssue,
    archiveIssues,
    sendIssues,
    resendIssues,
    deleteIssues,
    sendIssue,
  }
}
