import {
  useClientVendorsSummaryFetcher,
  useClientVendorRequestsSummaryFetcher,
} from 'apis'
import { localTimeZoneOffsetInHours } from 'utils/date'

export default function useVendorsFetcher(params) {
  const {
    page,
    sizePerPage,
    sortField,
    sortOrder,
    searchText,
    includeArchived,
    requests,
    vendorTags,
    vendorTagsAnyMatch,
    riskTiers,
    createdAtStart,
    createdAtEnd,
    nextReassessmentAtStart,
    nextReassessmentAtEnd,
    requestedBy,
    status,
  } = params
  const payload = {
    page,
    per_page: sizePerPage,
    search_query: searchText,
    ordered_by: sortField,
    direction: sortOrder,
    exclude_archived: !includeArchived,
    with_vendor_tags: vendorTags.join(','),
    any: vendorTagsAnyMatch,
    risk_tier_list: riskTiers,
    create_start_date: createdAtStart,
    create_end_date: createdAtEnd,
    next_start_date: nextReassessmentAtStart,
    next_end_date: nextReassessmentAtEnd,
    requested_by: requestedBy,
    status_list: status,
    tz: localTimeZoneOffsetInHours,
  }
  const vendorsSummaryResult = useClientVendorsSummaryFetcher(
    { params: payload },
    { enabled: !requests },
  )
  const vendorRequestsSummaryResult = useClientVendorRequestsSummaryFetcher(
    { params: payload },
    { enabled: requests },
  )
  return requests ? vendorRequestsSummaryResult : vendorsSummaryResult
}
