import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import sortBy from 'lodash/sortBy'
import isBefore from 'date-fns/isBefore'
import { toDate } from 'utils/date'
// import { log } from 'utils/debug'
import { getEndpointUrl, getPageUrl } from 'utils/url'
import { useInfiniteQuery } from 'hooks'

const makeFetcher = (key, queryFunc) => {
  const queryKey = (payload) => (payload ? [key, payload] : [key])
  const useFetcher = (payload, options) =>
    useQuery(
      queryKey(payload),
      () => queryFunc(payload).then((response) => response.data),
      options,
    )
  useFetcher.queryKey = queryKey
  return useFetcher
}

const makeCollectionFetcher = (key, queryFunc) => {
  const queryKey = (payload) => (payload ? [key, payload] : [key])
  const useFetcher = (payload, options) => {
    const result = useQuery(
      queryKey(payload),
      () =>
        queryFunc(payload).then((response) => ({
          data: response.data,
          totalSize: parseInt(response.headers.total, 10),
        })),
      options,
    )
    const { data: { data, totalSize } = {}, ...rest } = result
    return {
      data,
      totalSize,
      ...rest,
    }
  }
  useFetcher.queryKey = queryKey
  return useFetcher
}

const makeInfiniteFetcher = (key, queryFunc) => {
  const queryKey = (payload) => (payload ? [key, payload] : [key])
  const useCollectionFetcher = (payload, options) =>
    useInfiniteQuery(
      queryKey(payload),
      ({ pageParam = 1 }) => queryFunc(payload, pageParam),
      options,
    )
  useCollectionFetcher.queryKey = queryKey
  return useCollectionFetcher
}

/// // Client Context

// Get client assessment collection
export const getClientAssessments = (options) =>
  axios.get(getEndpointUrl('clientAssessments'), options)

export const searchClientAssessments = (body, options) =>
  axios.post(getEndpointUrl('clientAssessmentsSearch'), body, options)
export const useClientAssessmentsFetcher = makeCollectionFetcher(
  'clientAssessmentsSearch',
  ({ params, ...rest }) => searchClientAssessments(params, rest),
)

// Get a single client assessment
export const getClientAssessment = (id, options) =>
  axios.get(getEndpointUrl('clientAssessment', { id }), options)
export const useClientAssessmentFetcher = makeFetcher(
  'clientAssessment',
  ({ id, ...rest }) => getClientAssessment(id, rest),
)

export const getClientAssessmentSummary = (options) =>
  axios.get(getEndpointUrl('clientAssessmentSummary'), options)
export const useClientAssessmentSummaryFetcher = makeFetcher(
  'clientAssessmentSummary',
  getClientAssessmentSummary,
)

export const getClientAssessmentElements = (assessmentId, options) =>
  axios.get(
    getEndpointUrl('clientAssessmentElements', { id: assessmentId }),
    options,
  )
export const useClientAssessmentElementsInfiniteFetcher = makeInfiniteFetcher(
  'infiniteClientAssessmentElements',
  ({ assessmentId, ...options }, pageParam) =>
    getClientAssessmentElements(assessmentId, {
      ...options,
      params: {
        ...options.params,
        page: pageParam,
      },
    }),
)

export const getClientAssessmentRiskScoreRating = (assessmentId, options) =>
  axios.get(
    getEndpointUrl('clientAssessmentRiskScoreRating', { id: assessmentId }),
    options,
  )
export const useClientAssessmentRiskScoreRatingFetcher = makeFetcher(
  'clientAssessmentRiskScoreRating',
  ({ assessmentId, ...rest }) =>
    getClientAssessmentRiskScoreRating(assessmentId, rest),
)

export const getClientAssessmentAttachments = (assessmentId, options) =>
  axios.get(
    getEndpointUrl('clientAssessmentAttachments', { id: assessmentId }),
    options,
  )
export const useClientAssessmentAttachmentsFetcher = makeCollectionFetcher(
  'clientAssessmentAttachments',
  ({ assessmentId, ...rest }) =>
    getClientAssessmentAttachments(assessmentId, rest),
)

export const getClientAssessmentSection = (assessmentId, sectionId, options) =>
  axios.get(
    getEndpointUrl('clientAssessmentSection', { assessmentId, sectionId }),
    options,
  )
export const useClientAssessmentSectionFetcher = makeFetcher(
  'clientAssessmentSection',
  ({ assessmentId, sectionId, ...rest }) =>
    getClientAssessmentSection(assessmentId, sectionId, rest),
)

export const updateClientAssessment = (assessmentId, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessment', { id: assessmentId }),
    data,
    options,
  )
export const useUpdateClientAssessment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientAssessment(id, data).then((res) => res.data),
    {
      onSettled: (data, error, { id }) => {
        queryClient.invalidateQueries(useClientAssessmentFetcher.queryKey(), {
          predicate: (query) => query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const forceSubmitClientAssessment = (assessmentId, data, options) =>
  axios.post(
    getEndpointUrl('clientAssessmentForceSubmit', { id: assessmentId }),
    data,
    options,
  )
export const useForceSubmitClientAssessment = () =>
  useMutation(({ id, data }) =>
    forceSubmitClientAssessment(id, data).then((res) => res.data),
  )

export const undoSubmitClientAssessment = (assessmentId, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentUndoSubmit', { id: assessmentId }),
    data,
    options,
  )
export const useUndoSubmitClientAssessment = () =>
  useMutation(({ id, data }) =>
    undoSubmitClientAssessment(id, data).then((res) => res.data),
  )

export const continueReviewClientAssessment = (assessmentId, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentContinueReview', { id: assessmentId }),
    data,
    options,
  )
export const useContinueReviewClientAssessment = () =>
  useMutation(({ id, data }) =>
    continueReviewClientAssessment(id, data).then((res) => res.data),
  )

export const completeReviewClientAssessment = (assessmentId, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentCompleteReview', { id: assessmentId }),
    data,
    options,
  )
export const useCompleteReviewClientAssessment = () =>
  useMutation(({ id, data, options }) =>
    completeReviewClientAssessment(id, data, options).then((res) => res.data),
  )

export const importClientAssessmentResponse = (assessmentId, data, options) =>
  axios.post(
    getEndpointUrl('clientAssessmentResponseImport', { assessmentId }),
    data,
    options,
  )
export const useImportClientAssessmentResponse = () =>
  useMutation(({ assessmentId, data }) =>
    importClientAssessmentResponse(assessmentId, data).then((res) => res.data),
  )

// Get Client Global Profiles
export const getClientGlobalProfilesSummary = (options) =>
  axios.get(getEndpointUrl('clientGlobalProfilesSummary'), options)
export const useClientGlobalProfilesSummaryFetcher = makeCollectionFetcher(
  'clientGlobalProfilesSummary',
  getClientGlobalProfilesSummary,
)

// Get client vendors collection
export const getClientVendors = (options) =>
  axios.get(getEndpointUrl('clientVendors'), options)
export const useClientVendorsFetcher = makeCollectionFetcher(
  'clientVendors',
  getClientVendors,
)

export const createClientVendor = (data, options) =>
  axios.post(getEndpointUrl('clientVendors'), data, options)
export const useCreateClientVendor = () =>
  useMutation(({ data }) => createClientVendor(data).then((res) => res.data))

export const getClientVendor = (id, options) =>
  axios.get(getEndpointUrl('clientVendor', { id }), options)
export const useClientVendorFetcher = makeFetcher(
  'clientVendor',
  ({ id, ...rest }) => getClientVendor(id, rest),
)

export const updateClientVendor = (id, data, options) =>
  axios.patch(getEndpointUrl('clientVendor', { id }), data, options)
export const useUpdateClientVendor = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientVendor(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          (current) => ({
            ...pickNewData(current, data),
            organization_detail: pickNewData(
              current.organization_detail,
              data.organization_detail,
            ),
            custom_field_data: current.custom_field_data,
            vendor_tag_list: current.vendor_tag_list,
          }),
        )
      },
      onSettled: (data, error, { id }) => {
        queryClient.invalidateQueries(useClientVendorFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
        queryClient.invalidateQueries(useClientVendorsFetcher.queryKey())
      },
    },
  )
}

export const proposeClientVendor = (data, options) =>
  axios.post(getEndpointUrl('clientVendorsPropose'), data, options)
export const useProposeClientVendor = () =>
  useMutation(({ data }) => proposeClientVendor(data).then((res) => res.data))

export const getClientVendorRequesters = (options) =>
  axios.get(getEndpointUrl('clientVendorsRequesters'), options)
export const useClientVendorRequestersFetcher = makeCollectionFetcher(
  'clientVendorsRequesters',
  getClientVendorRequesters,
)

export const openDraftClientVendor = (vendorId, options) =>
  axios.patch(
    getEndpointUrl('clientVendorRequestOpenReview', { id: vendorId }),
    options,
  )

export const declineClientVendorRequest = (id, options) =>
  axios.patch(getEndpointUrl('clientVendorRequestDecline', { id }), options)
export const useDeclineClientVendorRequest = () =>
  useMutation(({ id }) =>
    declineClientVendorRequest(id).then((res) => res.data),
  )

export const approveClientVendorRequest = (vendorId, data, options) =>
  axios.patch(
    getEndpointUrl('clientVendorRequestApprove', { id: vendorId }),
    data,
    options,
  )
export const useApproveClientVendorRequest = () =>
  useMutation(({ id, data }) =>
    approveClientVendorRequest(id, data).then((res) => res.data),
  )

export const bulkImportClientVendors = (data, options) =>
  axios.post(getEndpointUrl('clientVendorsBulkImport'), data, options)
export const useBulkImportClientVendors = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => bulkImportClientVendors(data).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useClientVendorsSummaryFetcher.queryKey())
        queryClient.invalidateQueries(
          useClientVendorRequestsSummaryFetcher.queryKey(),
        )
      },
    },
  )
}

export const bulkImportClientVendorCustomFields = (data, options) =>
  axios.post(
    getEndpointUrl('clientVendorsBulkCustomFieldImport'),
    data,
    options,
  )
export const useBulkImportClientVendorCustomFields = () =>
  useMutation(({ data }) =>
    bulkImportClientVendorCustomFields(data).then((res) => res.data),
  )

export const getClientVendorsSummary = (options) =>
  axios.get(getEndpointUrl('clientVendorsSummary'), options)
export const useClientVendorsSummaryFetcher = makeCollectionFetcher(
  'clientVendorsSummary',
  getClientVendorsSummary,
)

export const getClientVendorRequestsSummary = (options) =>
  axios.get(getEndpointUrl('clientVendorRequestsSummary'), options)
export const useClientVendorRequestsSummaryFetcher = makeCollectionFetcher(
  'clientVendorRequestsSummary',
  getClientVendorRequestsSummary,
)

export const getClientSimilarVendors = (options) =>
  axios.get(getEndpointUrl('clientVendorsSimilar'), options)

export const getClientVendorNames = (options) =>
  axios.get(getEndpointUrl('clientVendorNames'), options)
export const useClientVendorNamesFetcher = makeFetcher(
  'clientVendorNames',
  getClientVendorNames,
)

export const getClientVendorAttachments = (options) =>
  axios.get(getEndpointUrl('clientVendorAttachments'), options)

export const createClientVendorAttachment = (data, options) =>
  axios.post(getEndpointUrl('clientVendorAttachments'), data, options)
export const useCreateClientVendorAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientVendorAttachment(data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientSourcedAttachmentsFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientVendorAttachment = (id, data, options) =>
  axios.patch(getEndpointUrl('clientVendorAttachment', { id }), data, options)
export const useUpdateClientVendorAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientVendorAttachment(id, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientSourcedAttachmentsFetcher.queryKey(),
        )
      },
    },
  )
}

export const deleteClientVendorAttachment = (id, options) =>
  axios.delete(getEndpointUrl('clientVendorAttachment', { id }), options)
export const useDeleteClientVendorAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteClientVendorAttachment(id).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientSourcedAttachmentsFetcher.queryKey(),
        )
      },
    },
  )
}

export const getClientVendorIntegrationLink = (id, params, options) =>
  axios.get(
    getEndpointUrl('clientVendorIntegrationLink', { id }, params),
    options,
  )

export const getClientSourcedAttachments = (options) =>
  axios.get(getEndpointUrl('clientSourcedAttachments'), options)
export const useClientSourcedAttachmentsFetcher = makeCollectionFetcher(
  'clientSourcedAttachments',
  getClientSourcedAttachments,
)

export const getClientVendorAttachmentsForVendor = (vendorId, options) =>
  axios.get(
    getEndpointUrl('clientAttachmentsForVendor', { id: vendorId }),
    options,
  )

export const getClientVendorResultsSummary = (vendorId, options) =>
  axios.get(
    getEndpointUrl('clientVendorResultsSummary', { id: vendorId }),
    options,
  )
export const useClientVendorResultsSummaryFetcher = makeCollectionFetcher(
  'clientVendorResultsSummary',
  ({ vendorId, ...rest }) => getClientVendorResultsSummary(vendorId, rest),
)

export const getClientVendorFacetableSets = (vendorId, options) =>
  axios.get(
    getEndpointUrl('clientVendorFacetableSets', { id: vendorId }),
    options,
  )
export const useClientVendorFacetableSetsFetcher = makeFetcher(
  'clientVendorFacetableSets',
  ({ vendorId, ...rest }) => getClientVendorFacetableSets(vendorId, rest),
)

export const getClientVendorProjects = (vendorId, options) =>
  axios.get(getEndpointUrl('clientVendorProjects', { id: vendorId }), options)
export const useClientVendorProjectsFetcher = makeCollectionFetcher(
  'clientVendorProjects',
  ({ vendorId, ...rest }) => getClientVendorProjects(vendorId, rest),
)

export const getClientVendorOrganizationDetail = (vendorId, options) =>
  axios.get(
    getEndpointUrl('clientVendorOrganizationDetail', { id: vendorId }),
    options,
  )

export const updateClientVendorOrganizationDetail = (vendorId, data, options) =>
  axios.patch(
    getEndpointUrl('clientVendorOrganizationDetail', { id: vendorId }),
    data,
    options,
  )
export const useUpdateClientVendorOrganizationDetail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ vendorId, data }) =>
      updateClientVendorOrganizationDetail(vendorId, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data, { vendorId }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
              query.queryKey[1].id === vendorId,
          },
          (current) => ({
            ...current,
            organization_detail: pickNewData(current.organization_detail, data),
          }),
        )
      },
      onSettled: (data, error, { vendorId }) => {
        queryClient.invalidateQueries(useClientVendorFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
            query.queryKey[1].id === vendorId,
        })
      },
    },
  )
}

export const updateClientVendorTags = (vendorId, data, options) =>
  axios.post(
    getEndpointUrl('clientVendorVendorTags', { id: vendorId }),
    data,
    options,
  )
export const useUpdateClientVendorTags = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ vendorId, data }) =>
      updateClientVendorTags(vendorId, data).then((res) => res.data),
    {
      onSuccess: (data, { vendorId }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
              query.queryKey[1].id === vendorId,
          },
          (current) => ({
            ...current,
            vendor_tag_list: data.map((e) => e.id),
          }),
        )
      },
      onSettled: (data, error, { vendorId }) => {
        queryClient.invalidateQueries(useClientVendorFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
            query.queryKey[1].id === vendorId,
        })
      },
    },
  )
}

// Update a single element of client assessment
export const updateClientElement = (id, data, options) =>
  axios.patch(getEndpointUrl('clientElement', { id }), data, options)
export const useUpdateClientElement = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientElement(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        setElementToClientAssessmentTemplateQueriesData(queryClient, data)
        invalidateClientAssessmentTemplateQueries(
          queryClient,
          data.assessment_template_id,
        )
      },
    },
  )
}

// Delete a single client element
export const deleteClientElement = (id, options) =>
  axios.delete(getEndpointUrl('clientElement', { id }), options)
export const useDeleteClientElement = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteClientElement(id).then((res) => res.data),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
    },
  )
}

export const updateClientChoice = (id, data, options) =>
  axios.patch(getEndpointUrl('clientChoice', { id }), data, options)
export const useUpdateClientChoice = () =>
  useMutation(({ id, data }) =>
    updateClientChoice(id, data).then((res) => res.data),
  )

// Delete a single client choice
export const deleteClientChoice = (id, options) =>
  axios.delete(getEndpointUrl('clientChoice', { id }), options)
export const useDeleteClientChoice = () =>
  useMutation(({ id }) => deleteClientChoice(id).then((res) => res.data))

export const getClientTags = (options) =>
  axios.get(getEndpointUrl('clientClientTags'), options)
export const useClientTagsFetcher = makeFetcher(
  'clientClientTags',
  getClientTags,
)

export const createClientTag = (data, options) =>
  axios.post(getEndpointUrl('clientClientTags'), data, options)
export const useCreateClientTag = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, options }) =>
      createClientTag(data, options).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(useClientTagsFetcher.queryKey(), (current) =>
          current && !current.some((e) => e.id === data.id)
            ? sortBy([...current, data], (e) => e.name.toLowerCase())
            : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientTagsFetcher.queryKey())
      },
    },
  )
}

export const updateClientTag = (id, data, options) =>
  axios.patch(getEndpointUrl('clientClientTag', { id }), data, options)
export const useUpdateClientTag = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data, options }) =>
      updateClientTag(id, data, options).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(useClientTagsFetcher.queryKey(), (current) =>
          current
            ? sortBy(
                current.map((e) => (e.id === id ? data : e)),
                (e) => e.name.toLowerCase(),
              )
            : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientTagsFetcher.queryKey())
      },
    },
  )
}

export const deleteClientTag = (id, options) =>
  axios.delete(getEndpointUrl('clientClientTag', { id }), options)
export const useDeleteClientTag = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, options }) => deleteClientTag(id, options).then((res) => res.data),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData(useClientTagsFetcher.queryKey(), (current) =>
          current ? current.filter((e) => e.id !== id) : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientTagsFetcher.queryKey())
      },
    },
  )
}

export const getClientUserGroups = (data, options) =>
  axios.get(getEndpointUrl('clientUserGroups'), data, options)
export const useClientUserGroupsFetcher = makeFetcher(
  'clientUserGroups',
  getClientUserGroups,
)

export const createClientUserGroup = (data, options) =>
  axios.post(getEndpointUrl('clientUserGroups'), data, options)
export const useCreateClientUserGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, options }) =>
      createClientUserGroup(data, options).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientUserGroupsFetcher.queryKey(),
          (current) =>
            current && !current.some((e) => e.id === data.id)
              ? [...current, data]
              : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientUserGroupsFetcher.queryKey())
      },
    },
  )
}

export const updateClientUserGroup = (id, data, options) =>
  axios.patch(getEndpointUrl('clientUserGroup', { id }), data, options)
export const useUpdateClientUserGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data, options }) =>
      updateClientUserGroup(id, data, options).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientUserGroupsFetcher.queryKey(),
          (current) =>
            current ? current.map((e) => (e.id === id ? data : e)) : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientUserGroupsFetcher.queryKey())
      },
    },
  )
}

export const deleteClientUserGroup = (id, options) =>
  axios.delete(getEndpointUrl('clientUserGroup', { id }), options)
export const useDeleteClientUserGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, options }) =>
      deleteClientUserGroup(id, options).then((res) => res.data),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData(
          useClientUserGroupsFetcher.queryKey(),
          (current) => (current ? current.filter((e) => e.id !== id) : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientUserGroupsFetcher.queryKey())
      },
    },
  )
}

// Get client assessment templates
export const getClientAssessmentTemplates = (options) =>
  axios.get(getEndpointUrl('clientAssessmentTemplates'), options)
export const useClientAssessmentTemplatesFetcher = makeCollectionFetcher(
  'clientAssessmentTemplates',
  getClientAssessmentTemplates,
)
export const useClientAssessmentTemplatesInfiniteFetcher = makeInfiniteFetcher(
  'infiniteClientAssessmentTemplates',
  (options, pageParam) =>
    getClientAssessmentTemplates({
      ...options,
      params: {
        ...options.params,
        page: pageParam,
      },
    }),
)

// Get a single client assessment template
export const getClientAssessmentTemplate = (id, options) =>
  axios.get(getEndpointUrl('clientAssessmentTemplate', { id }), options)
export const useClientAssessmentTemplateFetcher = makeFetcher(
  'clientAssessmentTemplate',
  ({ id, ...rest }) => getClientAssessmentTemplate(id, rest),
)

// create a new client assessment template
export const createClientAssessmentTemplate = (options) =>
  axios.post(getEndpointUrl('clientAssessmentTemplates'), options)
export const useCreateClientAssessmentTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation(
    () => createClientAssessmentTemplate().then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useClientAssessmentTemplatesFetcher.queryKey(),
        )
      },
    },
  )
}

// Update a single client assessment template
export const updateClientAssessmentTemplate = (id, data, options) =>
  axios.patch(getEndpointUrl('clientAssessmentTemplate', { id }), data, options)
export const useUpdateClientAssessmentTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientAssessmentTemplate(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onError: (error, { id }) => {
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Duplicate a single client assessment template
export const duplicateClientAssessmentTemplate = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateDuplicate', { id }),
    data,
    options,
  )
export const useDuplicateClientAssessmentTemplate = () =>
  useMutation(({ id, data }) =>
    duplicateClientAssessmentTemplate(id, data).then((res) => res.data),
  )

// Archive a single client assessment template
export const archiveClientAssessmentTemplate = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateArchive', { id }),
    data,
    options,
  )
export const useArchiveClientAssessmentTemplate = () =>
  useMutation(({ id, data }) =>
    archiveClientAssessmentTemplate(id, data).then((res) => res.data),
  )

// MakeReady a single client assessment template
export const makeReadyClientAssessmentTemplate = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateMakeReady', { id }),
    data,
    options,
  )
export const useMakeReadyClientAssessmentTemplate = () =>
  useMutation(({ id, data }) =>
    makeReadyClientAssessmentTemplate(id, data).then((res) => res.data),
  )

// Unready a single client assessment template
export const unreadyClientAssessmentTemplate = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateUnready', { id }),
    data,
    options,
  )
export const useUnreadyClientAssessmentTemplate = () =>
  useMutation(({ id, data }) =>
    unreadyClientAssessmentTemplate(id, data).then((res) => res.data),
  )

// Get client assessment template elements
export const getClientAssessmentTemplateElements = (id, options) =>
  axios.get(getEndpointUrl('clientAssessmentTemplateElements', { id }), options)

// Create an element of a client assessment template
export const createClientAssessmentTemplateElement = (id, data, options) =>
  axios.post(
    getEndpointUrl('clientAssessmentTemplateElements', { id }),
    data,
    options,
  )
export const useCreateClientAssessmentTemplateElement = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      createClientAssessmentTemplateElement(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        setClientAssessmentTemplateQueriesData(queryClient, id, (current) => ({
          ...current,
          elements: [
            ...current.elements.map((e) => ({
              ...e,
              sort_order:
                e.parent_id === data.parent_id &&
                e.sort_order >= data.sort_order
                  ? e.sort_order + 1
                  : e.sort_order,
            })),
            data,
          ],
        }))
      },
      onSettled: (data, error, { id }) => {
        // If we added new question right before/after of another question, question labels can be stale
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Multi-Create elements of a client assessment template
export const multiCreateClientAssessmentTemplateElements = (
  id,
  data,
  options,
) =>
  axios.post(
    getEndpointUrl('clientAssessmentTemplateElementsMultiCreate', { id }),
    data,
    options,
  )

// Multi-Copy elements of a client assessment template
export const multiCopyClientAssessmentTemplateElements = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateElementsMultiCopy', { id }),
    data,
    options,
  )
export const useMultiCopyClientAssessmentTemplateElements = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      multiCopyClientAssessmentTemplateElements(id, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onError: (error, { id }) => {
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Multi-Update elements of a client assessment template
export const multiUpdateClientAssessmentTemplateElements = (
  id,
  data,
  options,
) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateElementsMultiUpdate', { id }),
    data,
    options,
  )
export const useMultiUpdateClientAssessmentTemplateElements = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      multiUpdateClientAssessmentTemplateElements(id, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onError: (error, { id }) => {
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Move elements of a client assessment template
export const moveClientAssessmentTemplateElements = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentTemplateElementsMove', { id }),
    data,
    options,
  )
export const useMoveClientAssessmentTemplateElements = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      moveClientAssessmentTemplateElements(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onError: (error, { id }) => {
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Multi-Delete elements of a client assessment template
export const multiDeleteClientAssessmentTemplateElements = (
  id,
  data,
  options = {},
) =>
  axios({
    method: 'delete',
    url: getEndpointUrl('clientAssessmentTemplateElementsMultiDelete', { id }),
    data,
    ...options,
  })
export const useMultiDeleteClientAssessmentTemplateElements = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      multiDeleteClientAssessmentTemplateElements(id, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onError: (error, { id }) => {
        invalidateClientAssessmentTemplateQueries(queryClient, id)
      },
    },
  )
}

// Check assessmens template attachment presence
export const checkClientAssessmentTemplateAttachmentPresence = (id, options) =>
  axios.get(
    getEndpointUrl('clientAssessmentTemplateAttachmentsCheck', { id }),
    options,
  )
export const useCheckClientAssessmentTemplateAttachmentPresence = () =>
  useMutation(({ id }) =>
    checkClientAssessmentTemplateAttachmentPresence(id).then((res) => res.data),
  )

// Copy a single client element section
export const copyClientElementSection = (id, data, options) =>
  axios.post(getEndpointUrl('clientElementCopySection', { id }), data, options)
export const useCopyClientElementSection = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      copyClientElementSection(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        replaceClientAssessmentTemplateQueriesData(queryClient, data)
      },
    },
  )
}

// Global Assessment Templates
export const getClientGlobalAssessmentTemplates = (options) =>
  axios.get(getEndpointUrl('clientGlobalAssessmentTemplates'), options)
export const useClientGlobalAssessmentTemplatesFetcher = makeCollectionFetcher(
  'clientGlobalAssessmentTemplates',
  getClientGlobalAssessmentTemplates,
)

export const addClientGlobalAssessmentTemplateFromLibrary = (
  id,
  data,
  options,
) =>
  axios.post(
    getEndpointUrl('clientGlobalAssessmentTemplatesAddFromLibrary', { id }),
    data,
    options,
  )
export const useAddClientGlobalAssessmentTemplateFromLibrary = () =>
  useMutation(({ id, data }) =>
    addClientGlobalAssessmentTemplateFromLibrary(id, data).then(
      (res) => res.data,
    ),
  )

// Get client issues collection
export const getClientIssues = (options) =>
  axios.get(getEndpointUrl('clientIssues'), options)
export const useClientIssuesFetcher = makeCollectionFetcher(
  'clientIssues',
  getClientIssues,
)

// Get a client issue
export const getClientIssue = (id, options) =>
  axios.get(getEndpointUrl('clientIssue', { id }), options)
export const useClientIssueFetcher = makeFetcher('clientIssue', ({ id }) =>
  getClientIssues(id),
)

export const createClientIssue = (data, options) =>
  axios.post(getEndpointUrl('clientIssues'), data, options)
export const useCreateClientIssue = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientIssue(data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useClientIssuesFetcher.queryKey())
      },
    },
  )
}

// Update client issues
export const updateClientIssues = (data, options) =>
  axios.patch(getEndpointUrl('clientIssues'), data, options)
export const useUpdateClientIssues = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => updateClientIssues(data).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useClientIssuesFetcher.queryKey())
      },
    },
  )
}

// Update a client issue
export const updateClientIssue = (id, data, options) =>
  axios.patch(getEndpointUrl('clientIssue', { id }), data, options)
export const useUpdateClientIssue = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientIssue(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientIssueFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          data,
        )
      },
      onError: (error, { id }) => {
        queryClient.invalidateQueries(useClientIssueFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientIssueFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const deleteClientIssue = (id, options) =>
  axios.delete(getEndpointUrl('clientIssue', { id }), options)
export const useDeleteClientIssue = () =>
  useMutation(({ id }) => deleteClientIssue(id).then((res) => res.data))

// Get client issue categories
export const getClientIssueCategories = (options) =>
  axios.get(getEndpointUrl('clientIssueCategories'), options)
export const useClientIssueCategoriesFetcher = makeFetcher(
  'clientIssueCategories',
  getClientIssueCategories,
)

export const createClientIssueCategory = (data, options) =>
  axios.post(getEndpointUrl('clientIssueCategories'), data, options)
export const useCreateClientIssueCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, options }) =>
      createClientIssueCategory(data, options).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientIssueCategoriesFetcher.queryKey(),
          (current) =>
            current && !current.some((e) => e.id === data.id)
              ? sortBy([...current, data], (e) => e.name.toLowerCase())
              : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientIssueCategoriesFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientIssueCategory = (id, data, options) =>
  axios.patch(getEndpointUrl('clientIssueCategory', { id }), data, options)
export const useUpdateClientIssueCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data, options }) =>
      updateClientIssueCategory(id, data, options).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientIssueCategoriesFetcher.queryKey(),
          (current) =>
            current
              ? sortBy(
                  current.map((e) => (e.id === id ? data : e)),
                  (e) => e.name.toLowerCase(),
                )
              : current,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientIssueCategoriesFetcher.queryKey(),
        )
      },
    },
  )
}

export const deleteClientIssueCategory = (id, options) =>
  axios.delete(getEndpointUrl('clientIssueCategory', { id }), options)
export const useDeleteClientIssueCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, options }) =>
      deleteClientIssueCategory(id, options).then((res) => res.data),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData(
          useClientIssueCategoriesFetcher.queryKey(),
          (current) => (current ? current.filter((e) => e.id !== id) : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientIssueCategoriesFetcher.queryKey(),
        )
      },
    },
  )
}

export const getClientIssueSummary = (options) =>
  axios.get(getEndpointUrl('clientIssueSummary'), options)
export const useClientIssueSummaryFetcher = makeCollectionFetcher(
  'clientIssueSummary',
  getClientIssueSummary,
)

export const postCommentToClientCommentThread = (data, options) =>
  axios.post(getEndpointUrl('clientCommentThreads'), data, options)
export const usePostCommentToClientCommentThread = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, options }) =>
      postCommentToClientCommentThread(data, options).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] ===
                useClientCommentThreadFetcher.queryKey()[0] &&
              query.queryKey[1].params.commentable_id ===
                variables.data.get('comment_thread[commentable_id]') &&
              query.queryKey[1].params.commentable_type ===
                variables.data.get('comment_thread[commentable_type]'),
          },
          data,
        )
      },
      onError: (_, variables) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === useClientCommentThreadFetcher.queryKey()[0] &&
            query.queryKey[1].params.commentable_id ===
              variables.data.get('comment_thread[commentable_id]') &&
            query.queryKey[1].params.commentable_type ===
              variables.data.get('comment_thread[commentable_type]'),
        })
      },
    },
  )
}

export const findClientCommentThread = (data, options) =>
  axios.post(getEndpointUrl('clientCommentThreadsFind'), data, options)
export const useClientCommentThreadFetcher = makeFetcher(
  'clientCommentThreadsFind',
  ({ params, ...rest }) => findClientCommentThread(params, rest),
)

export const getClientIssueStubs = (options) =>
  axios.get(getEndpointUrl('clientIssueStubs'), options)
export const useClientIssueStubsFetcher = makeFetcher(
  'clientIssueStubs',
  getClientIssueStubs,
)

export const getClientIssueStub = (id, options) =>
  axios.get(getEndpointUrl('clientIssueStub', { id }), options)

export const updateClientIssueStub = (id, data, options) =>
  axios.patch(getEndpointUrl('clientIssueStub', { id }), data, options)
export const useUpdateClientIssueStub = () =>
  useMutation(({ id, data }) =>
    updateClientIssueStub(id, data).then((res) => res.data),
  )

export const getClientClientSourceContracts = (options) =>
  axios.get(getEndpointUrl('clientClientSourceContracts'), options)
export const useClientClientSourceContractsFetcher = makeFetcher(
  'clientClientSourceContracts',
  getClientClientSourceContracts,
)

export const getClientClientSourceContract = (id, options) =>
  axios.get(getEndpointUrl('clientClientSourceContract', { id }), options)
export const useClientClientSourceContractFetcher = makeFetcher(
  'clientClientSourceContract',
  ({ id, ...rest }) => getClientClientSourceContract(id, rest),
)

export const getClientQuestionAutomations = (options) =>
  axios.get(getEndpointUrl('clientQuestionAutomations'), options)
export const useClientQuestionAutomationsFetcher = makeFetcher(
  'clientAssessmentRiskScoreRating',
  getClientQuestionAutomations,
)

export const createClientQuestionAutomation = (data, options) =>
  axios.post(getEndpointUrl('clientQuestionAutomations'), data, options)
export const useCreateClientQuestionAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientQuestionAutomation(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        setElementToClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onSettled: (data, error, variables) => {
        invalidateClientAssessmentTemplateQueries(
          queryClient,
          variables.data instanceof FormData
            ? variables.data.get('assessment_template_id')
            : variables.data.assessment_template_id,
        )
      },
    },
  )
}

export const updateClientQuestionAutomation = (id, data, options) =>
  axios.patch(getEndpointUrl('clientQuestionAutomation', { id }), data, options)
export const useUpdateClientQuestionAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientQuestionAutomation(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        setElementToClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onSettled: (data, error, variables) => {
        invalidateClientAssessmentTemplateQueries(
          queryClient,
          variables.data instanceof FormData
            ? variables.data.get('assessment_template_id')
            : variables.data.assessment_template_id,
        )
      },
    },
  )
}

export const updateClientAssessmentQuestionAutomation = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentQuestionAutomation', { id }),
    data,
    options,
  )

export const updateClientAssessmentQuestionAutomations = (data, options) =>
  axios.patch(
    getEndpointUrl('clientAssessmentQuestionAutomations'),
    data,
    options,
  )
export const useUpdateClientAssessmentQuestionAutomations = () =>
  useMutation(({ data }) =>
    updateClientAssessmentQuestionAutomations(data).then((res) => res.data),
  )

export const deleteClientQuestionAutomation = (id, options) =>
  axios.delete(getEndpointUrl('clientQuestionAutomation', { id }), options)
export const useDeleteClientQuestionAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, options }) =>
      deleteClientQuestionAutomation(id, options).then((res) => res.data),
    {
      onSuccess: (data) => {
        setElementToClientAssessmentTemplateQueriesData(queryClient, data)
      },
      onSettled: (data, error, variables) => {
        invalidateClientAssessmentTemplateQueries(
          queryClient,
          variables.options.data.assessment_template_id,
        )
      },
    },
  )
}

// Get client projects
export const getClientProjects = (options) =>
  axios.get(getEndpointUrl('clientProjects'), options)

export const getClientProjectsWithCounts = (options) =>
  axios.get(getEndpointUrl('clientProjectsWithCounts'), options)
export const useClientProjectsWithCountsFetcher = makeCollectionFetcher(
  'clientProjectsWithCounts',
  getClientProjectsWithCounts,
)

export const getClientProjectNames = (options) =>
  axios.get(getEndpointUrl('clientProjectNames'), options)
export const useClientProjectNamesFetcher = makeFetcher(
  'clientProjectNames',
  getClientProjectNames,
)

export const getClientNewProject = (options) =>
  axios.get(getEndpointUrl('clientProjectNew'), options)
export const useClientNewProjectFetcher = makeFetcher(
  'clientProjectNew',
  getClientNewProject,
)

export const createClientProject = (data, options) =>
  axios.post(getEndpointUrl('clientProjects'), data, options)
export const useCreateClientProject = () =>
  useMutation(({ data }) => createClientProject(data).then((res) => res.data))

export const getClientProject = (id, options) =>
  axios.get(getEndpointUrl('clientProject', { id }), options)
export const useClientProjectFetcher = makeFetcher('clientProject', ({ id }) =>
  getClientProject(id),
)

export const updateClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProject', { id }), data, options)
export const useUpdateClientProject = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data, ...rest }) =>
      updateClientProject(id, data, rest).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientProjectFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          (current) => pickNewData(current, data),
        )
      },
      onError: (error, { id }) => {
        queryClient.invalidateQueries(useClientProjectFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientProjectFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const deleteClientProject = (id, options) =>
  axios.delete(getEndpointUrl('clientProject', { id }), options)
export const useDeleteClientProject = () =>
  useMutation(({ id, ...rest }) =>
    deleteClientProject(id, rest).then((res) => res.data),
  )

export const launchClientProjectEarly = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectLaunch', { id }), data, options)
export const useLaunchClientProjectEarly = () =>
  useMutation(({ id, data, ...rest }) =>
    launchClientProjectEarly(id, data, rest).then((res) => res.data),
  )

export const relaunchClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectRelaunch', { id }), data, options)
export const useRelaunchClientProject = () =>
  useMutation(({ id, data, ...rest }) =>
    relaunchClientProject(id, data, rest).then((res) => res.data),
  )

export const archiveClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectArchive', { id }), data, options)
export const useArchiveClientProject = () =>
  useMutation(({ id, data, ...rest }) =>
    archiveClientProject(id, data, rest).then((res) => res.data),
  )

export const unarchiveClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectUnarchive', { id }), data, options)
export const useUnarchiveClientProject = () =>
  useMutation(({ id, data, ...rest }) =>
    unarchiveClientProject(id, data, rest).then((res) => res.data),
  )

export const reviewClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectReview', { id }), data, options)
export const useReviewClientProject = () =>
  useMutation(({ id, data, ...rest }) =>
    reviewClientProject(id, data, rest).then((res) => res.data),
  )

export const closeClientProject = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectClose', { id }), data, options)
export const useCloseClientProject = () =>
  useMutation(({ id, data, ...rest }) =>
    closeClientProject(id, data, rest).then((res) => res.data),
  )

export const getClientProjectProjectVendors = (projectId, options) =>
  axios.get(
    getEndpointUrl('clientProjectProjectVendors', { projectId }),
    options,
  )
export const useClientProjectProjectVendorsFetcher = makeCollectionFetcher(
  'clientProjectProjectVendors',
  ({ projectId, ...rest }) => getClientProjectProjectVendors(projectId, rest),
)

export const addMultiProjectVendorsToClientProject = (id, data, options) =>
  axios.post(
    getEndpointUrl('clientProjectMultiProjectVendors', { id }),
    data,
    options,
  )
export const useAddMultiProjectVendorsToClientProject = () =>
  useMutation(({ id, data }) =>
    addMultiProjectVendorsToClientProject(id, data).then((res) => res.data),
  )

export const updateMultiProjectVendorsForClientProject = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectMultiProjectVendors', { id }),
    data,
    options,
  )
export const useUpdateMultiProjectVendorsForClientProject = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ projectId, data }) =>
      updateMultiProjectVendorsForClientProject(projectId, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useClientProjectProjectVendorsFetcher.queryKey(),
        )
      },
    },
  )
}

export const getClientProjectTotals = (id, options) =>
  axios.get(getEndpointUrl('clientProjectTotals', { id }), options)
export const useClientProjectTotalsFetcher = makeFetcher(
  'clientProjectTotals',
  ({ id, ...rest }) => getClientProjectTotals(id, rest),
)

export const reinviteClientProjectVendor = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectVendorReinvite', { id }),
    data,
    options,
  )
export const useReinviteClientProjectVendor = () =>
  useMutation(({ projectVendorId }) =>
    reinviteClientProjectVendor(projectVendorId).then((res) => res.data),
  )

export const updateClientProjectVendor = (id, data, options) =>
  axios.patch(getEndpointUrl('clientProjectVendor', { id }), data, options)
export const useUpdateClientProjectVendor = () =>
  useMutation(({ id, data }) =>
    updateClientProjectVendor(id, data).then((res) => res.data),
  )

export const deleteClientProjectVendor = (id, options) =>
  axios.delete(getEndpointUrl('clientProjectVendor', { id }), options)
export const useDeleteClientProjectVendor = () =>
  useMutation(({ id }) => deleteClientProjectVendor(id).then((res) => res.data))

export const cancelClientProjectVendor = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectVendorCancel', { id }),
    data,
    options,
  )
export const useCancelClientProjectVendor = () =>
  useMutation(({ id, data }) =>
    cancelClientProjectVendor(id, data).then((res) => res.data),
  )

export const launchClientProjectVendor = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectVendorLaunch', { id }),
    data,
    options,
  )
export const useLaunchClientProjectVendor = () =>
  useMutation(({ id, data }) =>
    launchClientProjectVendor(id, data).then((res) => res.data),
  )

export const relaunchClientProjectVendor = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectVendorRelaunch', { id }),
    data,
    options,
  )
export const useRelaunchClientProjectVendor = () =>
  useMutation(({ id, data }) =>
    relaunchClientProjectVendor(id, data).then((res) => res.data),
  )

export const approveClientProjectVendorExtension = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientProjectVendorApproveExtension', { id }),
    data,
    options,
  )
export const useApproveClientProjectVendorExtension = () =>
  useMutation(({ id, data }) =>
    approveClientProjectVendorExtension(id, data).then((res) => res.data),
  )

export const getClientProjectEventSubscriptions = (projectId, options) =>
  axios.get(
    getEndpointUrl('clientProjectEventSubscriptions', { projectId }),
    options,
  )
export const useClientProjectEventSubscriptionsFetcher = makeCollectionFetcher(
  'clientProjectEventSubscriptions',
  ({ projectId, ...rest }) =>
    getClientProjectEventSubscriptions(projectId, rest),
)

export const addClientProjectEventSubscriptions = (projectId, data, options) =>
  axios.post(
    getEndpointUrl('clientProjectEventSubscriptionsAdd', { projectId }),
    data,
    options,
  )
export const useAddClientProjectEventSubscriptions = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ projectId, data }) =>
      addClientProjectEventSubscriptions(projectId, data).then(
        (res) => res.data,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientProjectEventSubscriptionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientProjectEventSubscribers = (projectId, data, options) =>
  axios.post(
    getEndpointUrl('clientProjectEventSubscriptionsUpdateSubscriber', {
      projectId,
    }),
    data,
    options,
  )
export const useUpdateClientProjectEventSubscribers = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ projectId, data }) =>
      updateClientProjectEventSubscribers(projectId, data).then(
        (res) => res.data,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientProjectEventSubscriptionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const removeClientProjectEventSubscribers = (projectId, data, options) =>
  axios.post(
    getEndpointUrl('clientProjectEventSubscriptionsRemoveEventSubscriber', {
      projectId,
    }),
    data,
    options,
  )
export const useRemoveClientProjectEventSubscribers = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ projectId, data }) =>
      removeClientProjectEventSubscribers(projectId, data).then(
        (res) => res.data,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientProjectEventSubscriptionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const reinviteClientVendorUser = (vendorId, userId, options) =>
  axios.patch(
    getEndpointUrl('clientVendorResendUserInvite', { vendorId, userId }),
    options,
  )
export const useReinviteClientVendorUser = () =>
  useMutation(({ vendorId, userId }) =>
    reinviteClientVendorUser(vendorId, userId).then((res) => res.data),
  )

export const getClientRiskDomains = (options) =>
  axios.get(getEndpointUrl('clientRiskDomains'), options)
export const useClientRiskDomainsFetcher = makeFetcher(
  'clientRiskDomains',
  getClientRiskDomains,
)

// Update a response
export const updateClientResponse = (id, data, options) =>
  axios.patch(getEndpointUrl('clientResponse', { id }), data, options)
export const useUpdateClientResponse = (config) =>
  useMutation(
    ({ id, data }) => updateClientResponse(id, data).then((res) => res.data),
    config,
  )

export const createClientResponseIssue = (responseId, data, options) =>
  axios.post(
    getEndpointUrl('clientResponseIssues', { id: responseId }),
    data,
    options,
  )
export const useCreateClientResponseIssue = () =>
  useMutation(({ responseId, data }) =>
    createClientResponseIssue(responseId, data).then((res) => res.data),
  )

export const getClientOrganizationPartners = (options) =>
  axios.get(getEndpointUrl('clientOrganizationPartners'), options)
export const useClientOrganizationPartnersFetcher = makeCollectionFetcher(
  'clientOrganizationPartners',
  getClientOrganizationPartners,
)

export const createClientOrganizationPartner = (data, options) =>
  axios.post(getEndpointUrl('clientOrganizationPartners'), data, options)
export const useCreateClientOrganizationPartner = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientOrganizationPartner(data).then((res) => res.data),
    {
      onSuccess: (data, { data: { type } }) => {
        queryClient.setQueriesData(
          useClientOrganizationPartnersFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: current.data.map((e) => (e.type === type ? data : e)),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientOrganizationPartnersFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientOrganizationPartner = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientOrganizationPartner', { id }),
    data,
    options,
  )
export const useUpdateClientOrganizationPartner = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientOrganizationPartner(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientOrganizationPartnersFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: current.data.map((e) => (e.id === id ? data : e)),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientOrganizationPartnersFetcher.queryKey(),
        )
      },
    },
  )
}

// Create an element of a client assessment template
export const createClientOrganizationPartnerMapping = (
  partnerId,
  data,
  options,
) =>
  axios.post(
    getEndpointUrl('clientOrganizationPartnerPartnerMapping', { partnerId }),
    data,
    options,
  )

// Delete a single client OrganizationPartnerMapping'
export const deleteClientOrganizationPartnerMapping = (id, options) =>
  axios.delete(
    getEndpointUrl('clientOrganizationPartnerMapping', { id }),
    options,
  )
export const useDeleteClientOrganizationPartnerMapping = () =>
  useMutation(({ id }) =>
    deleteClientOrganizationPartnerMapping(id).then((res) => res.data),
  )

export const createClientOrganizationGloborgMapping = (data, options) =>
  axios.post(getEndpointUrl('clientOrganizationGloborgMappings'), data, options)
export const useCreateClientOrganizationGloborgMapping = () =>
  useMutation(({ data }) =>
    createClientOrganizationGloborgMapping(data).then((res) => res.data),
  )

export const confirmClientOrganizationGloborgMapping = (id, data, options) =>
  axios.patch(
    getEndpointUrl('clientOrganizationGloborgMappingConfirm', { id }),
    data,
    options,
  )
export const useConfirmClientOrganizationGloborgMapping = () =>
  useMutation(({ id, data }) =>
    confirmClientOrganizationGloborgMapping(id, data).then((res) => res.data),
  )

export const deleteClientOrganizationGloborgMapping = (id, options) =>
  axios.delete(
    getEndpointUrl('clientOrganizationGloborgMapping', { id }),
    options,
  )
export const useDeleteClientOrganizationGloborgMapping = () =>
  useMutation(({ id }) =>
    deleteClientOrganizationGloborgMapping(id).then((res) => res.data),
  )

// manage custom risk tier calculators
export const getClientRiskTierCalculators = (options) =>
  axios.get(getEndpointUrl('clientRiskTierCalculators'), options)
export const useClientRiskTierCalculatorsFetcher = makeFetcher(
  'clientRiskTierCalculators',
  getClientRiskTierCalculators,
)

export const getClientRiskTierCalculator = (id, options) =>
  axios.get(getEndpointUrl('clientRiskTierCalculator', { id }), options)
export const useClientRiskTierCalculatorFetcher = makeFetcher(
  'clientRiskTierCalculator',
  ({ id }) => getClientRiskTierCalculator(id),
)

export const createClientRiskTierCalculator = (data, options) =>
  axios.post(getEndpointUrl('clientRiskTierCalculators'), data, options)
export const useCreateClientRiskTierCalculator = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientRiskTierCalculator(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientRiskTierCalculatorsFetcher.queryKey(),
          (current) => (current ? [...current, data] : undefined),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientRiskTierCalculatorsFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientRiskTierCalculator = (id, data, options) =>
  axios.put(getEndpointUrl('clientRiskTierCalculator', { id }), data, options)
export const useUpdateClientRiskTierCalculator = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientRiskTierCalculator(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientRiskTierCalculatorsFetcher.queryKey(),
          (current) =>
            current ? current.map((e) => (e.id === id ? data : e)) : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientRiskTierCalculatorsFetcher.queryKey(),
        )
      },
    },
  )
}

export const setActiveClientRiskTierCalculator = (id, options) =>
  axios.get(
    getEndpointUrl('setActiveClientRiskTierCalculator', { id }),
    options,
  )
export const useSetActiveClientRiskTierCalculator = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => setActiveClientRiskTierCalculator(id).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientRiskTierCalculatorsFetcher.queryKey(),
          data,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientRiskTierCalculatorsFetcher.queryKey(),
        )
      },
    },
  )
}

export const deleteClientRiskTierCalculator = (id, options) =>
  axios.delete(getEndpointUrl('clientRiskTierCalculator', { id }), options)
export const useDeleteClientRiskTierCalculator = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteClientRiskTierCalculator(id).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientRiskTierCalculatorsFetcher.queryKey(),
          (current) =>
            current ? current.filter((e) => e.id !== id) : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientRiskTierCalculatorsFetcher.queryKey(),
        )
      },
    },
  )
}

// manage custom field definitions
export const getClientCustomFieldDefinitions = (options) =>
  axios.get(getEndpointUrl('clientCustomFieldDefs'), options)
export const useClientCustomFieldDefinitionsFetcher = makeFetcher(
  'clientCustomFieldDefs',
  getClientCustomFieldDefinitions,
)

export const getClientCustomFieldDefinition = (id, options) =>
  axios.get(getEndpointUrl('clientCustomFieldDef', { id }), options)

export const createClientCustomFieldDefinition = (data, options) =>
  axios.post(getEndpointUrl('clientCustomFieldDefs'), data, options)
export const useCreateClientCustomFieldDefinition = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) =>
      createClientCustomFieldDefinition(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  field_definitions: [...current.field_definitions, data],
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientCustomFieldDefinition = (id, data, options) =>
  axios.put(getEndpointUrl('clientCustomFieldDef', { id }), data, options)
export const useUpdateClientCustomFieldDefinition = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateClientCustomFieldDefinition(id, data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  field_definitions: current.field_definitions.map((e) =>
                    e.id === data.id ? data : e,
                  ),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const sortClientCustomFieldDefinitions = (data, options) =>
  axios.patch(getEndpointUrl('sortClientCustomFieldDefs'), data, options)
export const useSortClientCustomFieldDefinitions = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) =>
      sortClientCustomFieldDefinitions(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  field_definitions: data,
                }
              : undefined,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const deleteClientCustomFieldDefinition = (id, options) =>
  axios.delete(getEndpointUrl('clientCustomFieldDef', { id }), options)
export const useDeleteClientCustomFieldDefinition = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteClientCustomFieldDefinition(id).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  field_definitions: current.field_definitions.filter(
                    (e) => e.id !== id,
                  ),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

// manage custom field groups
export const createClientCustomFieldGroup = (data, options) =>
  axios.post(getEndpointUrl('clientCustomFieldGroups'), data, options)
export const useCreateClientCustomFieldGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientCustomFieldGroup(data).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  groups: {
                    ...current.groups,
                    [variables.data.class]: data,
                  },
                }
              : undefined,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const updateClientCustomFieldGroup = (key, data, options) =>
  axios.patch(getEndpointUrl('clientCustomFieldGroup', { key }), data, options)
export const useUpdateClientCustomFieldGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ key, data }) =>
      updateClientCustomFieldGroup(key, data).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  groups: {
                    ...current.groups,
                    [variables.data.class]: {
                      ...current.groups[variables.data.class],
                      [variables.key]: data,
                    },
                  },
                }
              : undefined,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const sortClientCustomFieldGroups = (data, options) =>
  axios.patch(getEndpointUrl('sortClientCustomFieldGroups'), data, options)
export const useSortClientCustomFieldGroups = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => sortClientCustomFieldGroups(data).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  groups: {
                    ...current.groups,
                    [variables.data.class]: data,
                  },
                }
              : undefined,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

export const deleteClientCustomFieldGroup = (options) =>
  axios.delete(getEndpointUrl('deleteClientCustomFieldGroups'), options)
export const useDeleteClientCustomFieldGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ options }) =>
      deleteClientCustomFieldGroup(options).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  groups: {
                    ...current.groups,
                    [variables.options.data.class]: data,
                  },
                }
              : undefined,
        )
      },
      onError: () => {
        queryClient.invalidateQueries(
          useClientCustomFieldDefinitionsFetcher.queryKey(),
        )
      },
    },
  )
}

// export const getClientVendorCustomFieldData = (vendorId, options) =>
//   axios.get(
//     getEndpointUrl('clientVendorCustomFieldData', { id: vendorId }),
//     options,
//   )

export const updateClientVendorCustomFieldData = (vendorId, data, options) =>
  axios.patch(
    getEndpointUrl('clientVendorCustomFieldData', { id: vendorId }),
    data,
    options,
  )
export const useUpdateClientVendorCustomFieldData = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ vendorId, data }) =>
      updateClientVendorCustomFieldData(vendorId, data).then((res) => res.data),
    {
      onSuccess: (data, { vendorId }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
              query.queryKey[1].id === vendorId,
          },
          (current) => ({
            ...current,
            custom_field_data: data,
          }),
        )
      },
      onSettled: (data, error, { vendorId }) => {
        queryClient.invalidateQueries(useClientVendorFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
            query.queryKey[1].id === vendorId,
        })
      },
    },
  )
}

export const getClientWatchlists = (options) =>
  axios.get(getEndpointUrl('clientWatchlists'), options)
export const useClientWatchlistsFetcher = makeCollectionFetcher(
  'clientWatchlists',
  getClientWatchlists,
)

export const createClientWatchlist = (data, options) =>
  axios.post(getEndpointUrl('clientWatchlists'), data, options)
export const useCreateClientWatchlist = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createClientWatchlist(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useClientWatchlistsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: [...current.data, data],
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientWatchlistsFetcher.queryKey())
      },
    },
  )
}

export const updateClientWatchlist = (id, data, options) =>
  axios.put(getEndpointUrl('clientWatchlist', { id }), data, options)
export const useUpdateClientWatchlist = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientWatchlist(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useClientWatchlistsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: current.data.map((e) => (e.id === id ? data : e)),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientWatchlistsFetcher.queryKey())
      },
    },
  )
}

export const deleteClientWatchlist = (id, options) =>
  axios.delete(getEndpointUrl('clientWatchlist', { id }), options)
export const useDeleteClientWatchlist = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteClientWatchlist(id).then((res) => res.data),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData(
          useClientWatchlistsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: current.data.filter((e) => e.id !== id),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useClientWatchlistsFetcher.queryKey())
      },
    },
  )
}

export const updateClientGloborgOrg = (id, data, options) =>
  axios.put(getEndpointUrl('clientGloborgOrg', { id }), data, options)
export const useUpdateClientGloborgOrg = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateClientGloborgOrg(id, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useClientWatchlistsFetcher.queryKey())
        queryClient.invalidateQueries(
          useClientGlobalProfilesSummaryFetcher.queryKey(),
        )
      },
    },
  )
}

export const getClientAssessmentCycleTimeReport = (options) =>
  axios.get(getEndpointUrl('clientAssessmentCycleTimeReport'), options)
export const useClientAssessmentCycleTimeReportFetcher = makeFetcher(
  'clientAssessmentCycleTimeReport',
  getClientAssessmentCycleTimeReport,
)

export const getClientAssessmentMonthlyMetrics = (options) =>
  axios.get(getEndpointUrl('clientAssessmentMonthlyMetrics'), options)
export const useClientAssessmentMonthlyMetricsFetcher = makeFetcher(
  'clientAssessmentMonthlyMetrics',
  getClientAssessmentMonthlyMetrics,
)

export const getClientVendorAssessmentsStatus = (options) =>
  axios.get(getEndpointUrl('clientVendorAssessmentsStatus'), options)
export const useClientVendorAssessmentsStatusFetcher = makeFetcher(
  'clientVendorAssessmentsStatus',
  getClientVendorAssessmentsStatus,
)

export const getClientVendorFacetSets = (options) =>
  axios.get(getEndpointUrl('clientVendorFacetSets'), options)
export const useClientVendorFacetSetsFetcher = makeCollectionFetcher(
  'clientVendorFacetSets',
  getClientVendorFacetSets,
)

export const getClientVendorOfacStatus = (options) =>
  axios.get(getEndpointUrl('clientVendorOfacStatusReport'), options)
export const useClientVendorOfacStatus = makeCollectionFetcher(
  'clientVendorOfacStatusReport',
  getClientVendorOfacStatus,
)

/// // Vendor Context

export const getVendorAssessments = (options) =>
  axios.get(getEndpointUrl('vendorAssessments'), options)
export const useVendorAssessmentsFetcher = makeCollectionFetcher(
  'vendorAssessments',
  getVendorAssessments,
)

export const updateVendorAssessment = (id, data, options) =>
  axios.put(getEndpointUrl('vendorAssessment', { id }), data, options)
export const useUpdateVendorAssessment = () =>
  useMutation(({ id, data }) =>
    updateVendorAssessment(id, data).then((res) => res.data),
  )

export const validateVendorAssessment = (id, options) =>
  axios.post(getEndpointUrl('vendorAssessmentValidate', { id }), options)
export const useValidateVendorAssessment = () =>
  useMutation(({ id }) => validateVendorAssessment(id).then((res) => res.data))

export const getVendorAssessmentAutoAnswerable = (id, options) =>
  axios.get(getEndpointUrl('vendorAssessmentAutoAnswerable', { id }), options)
export const useVendorAssessmentAutoAnswerableFetcher = makeFetcher(
  'vendorAssessmentAutoAnswerable',
  ({ id, ...rest }) => getVendorAssessmentAutoAnswerable(id, rest),
)

export const autoAnswerVendorAssessment = (id, data, options) =>
  axios.post(
    getEndpointUrl('vendorAssessmentAutoAnswer', { id }),
    data,
    options,
  )
export const useAutoAnswerVendorAssessment = () =>
  useMutation(({ id, data }) =>
    autoAnswerVendorAssessment(id, data).then((res) => res.data),
  )

export const getVendorAssessmentSection = (assessmentId, sectionId, options) =>
  axios.get(
    getEndpointUrl('vendorAssessmentSection', { assessmentId, sectionId }),
    options,
  )
export const useVendorAssessmentSectionFetcher = makeFetcher(
  'vendorAssessmentSection',
  ({ assessmentId, sectionId, ...rest }) =>
    getVendorAssessmentSection(assessmentId, sectionId, rest),
)

export const getVendorAssessmentElements = (assessmentId, options) =>
  axios.get(
    getEndpointUrl('vendorAssessmentElements', { id: assessmentId }),
    options,
  )
export const useVendorAssessmentElementsInfiniteFetcher = makeInfiniteFetcher(
  'infiniteVendorAssessmentElements',
  ({ assessmentId, ...options }, pageParam) =>
    getVendorAssessmentElements(assessmentId, {
      ...options,
      params: {
        ...options.params,
        page: pageParam,
      },
    }),
)

export const getVendorAssessmentElementResponse = (
  assessmentId,
  elementId,
  options,
) =>
  axios.get(
    getEndpointUrl('vendorAssessmentElementResponse', {
      assessmentId,
      elementId,
    }),
    options,
  )

export const updateVendorAssessmentElementResponse = (
  assessmentId,
  elementId,
  data,
  options,
) =>
  axios.put(
    getEndpointUrl('vendorAssessmentElementResponse', {
      assessmentId,
      elementId,
    }),
    data,
    options,
  )
export const useUpdateVendorAssessmentElementResponse = () =>
  useMutation(({ assessmentId, elementId, data }) =>
    updateVendorAssessmentElementResponse(assessmentId, elementId, data).then(
      (res) => res.data,
    ),
  )

export const importVendorAssessmentResponse = (assessmentId, data, options) =>
  axios.post(
    getEndpointUrl('vendorAssessmentResponseImport', { assessmentId }),
    data,
    options,
  )
export const useImportVendorAssessmentResponse = () =>
  useMutation(({ assessmentId, data }) =>
    importVendorAssessmentResponse(assessmentId, data).then((res) => res.data),
  )

// Get vendor issues collection
export const getVendorIssues = (options) =>
  axios.get(getEndpointUrl('vendorIssues'), options)
export const useVendorIssuesFetcher = makeCollectionFetcher(
  'vendorIssues',
  getVendorIssues,
)

export const getVendorIssue = (id, options) =>
  axios.get(getEndpointUrl('vendorIssue', { id }), options)
export const useVendorIssueFetcher = makeFetcher('vendorIssue', ({ id }) =>
  getVendorIssue(id),
)

export const updateVendorIssue = (id, data, options) =>
  axios.patch(getEndpointUrl('vendorIssue', { id }), data, options)

export const touchVendorIssue = (id, data, options) =>
  axios.post(getEndpointUrl('vendorIssueTouch', { id }), data, options)
export const useTouchVendorIssue = () =>
  useMutation(({ id, data }) =>
    touchVendorIssue(id, data).then((res) => res.data),
  )

// Get vendor on_demand requestable projects
export const getVendorProjectsAutomations = (options) =>
  axios.get(getEndpointUrl('vendorProjectAutomations'), options)

export const requestVendorProjectsAutomations = (id, options) =>
  axios.put(getEndpointUrl('vendorProjectAutomationRequest', { id }), options)
export const useRequestVendorProjectsAutomations = () =>
  useMutation(({ id }) =>
    requestVendorProjectsAutomations(id).then((res) => res.data),
  )

export const requestVendorProjectExtension = (id, data, options) =>
  axios.patch(
    getEndpointUrl('vendorProjectRequestExtension', { id }),
    data,
    options,
  )
export const useRequestVendorProjectExtension = () =>
  useMutation(({ id, data }) =>
    requestVendorProjectExtension(id, data).then((res) => res.data),
  )

export const postCommentToVendorCommentThread = (data, options) =>
  axios.post(getEndpointUrl('vendorCommentThreads'), data, options)
export const usePostCommentToVendorCommentThread = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, options }) =>
      postCommentToVendorCommentThread(data, options).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] ===
                useVendorCommentThreadFetcher.queryKey()[0] &&
              query.queryKey[1].params.commentable_id ===
                variables.data.get('comment_thread[commentable_id]') &&
              query.queryKey[1].params.commentable_type ===
                variables.data.get('comment_thread[commentable_type]'),
          },
          data,
        )
      },
      onError: (_, variables) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === useVendorCommentThreadFetcher.queryKey()[0] &&
            query.queryKey[1].params.commentable_id ===
              variables.data.get('comment_thread[commentable_id]') &&
            query.queryKey[1].params.commentable_type ===
              variables.data.get('comment_thread[commentable_type]'),
        })
      },
    },
  )
}

export const findVendorCommentThread = (data, options) =>
  axios.post(getEndpointUrl('vendorCommentThreadsFind'), data, options)
export const useVendorCommentThreadFetcher = makeFetcher(
  'vendorCommentThreadsFind',
  ({ params, ...rest }) => findVendorCommentThread(params, rest),
)

// global context

export const getOrganizations = (options) =>
  axios.get(getEndpointUrl('organizations'), options)
export const useOrganizationsFetcher = makeCollectionFetcher(
  'organizations',
  getOrganizations,
)

export const getCurrentOrganization = (options) =>
  axios.get(`${getEndpointUrl('currentOrganization')}.json`, options)
export const useCurrentOrganizationFetcher = makeFetcher(
  'currentOrganization',
  getCurrentOrganization,
)

export const getUsers = (options) => axios.get(getEndpointUrl('users'), options)
export const useUsersFetcher = makeCollectionFetcher('users', getUsers)
export const useUsersInfiniteFetcher = makeInfiniteFetcher(
  'infiniteUsers',
  (options, pageParam) =>
    getUsers({
      ...options,
      params: {
        ...options.params,
        page: pageParam,
      },
    }),
)

export const updateUser = (id, data, options) =>
  axios.patch(getEndpointUrl('user', { id }), data, options)
export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateUser(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(useUsersFetcher.queryKey(), (current) =>
          current
            ? {
                ...current,
                data: current.data.map((user) =>
                  user.id === id ? data : user,
                ),
              }
            : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useUsersFetcher.queryKey())
      },
    },
  )
}

export const setUserAPIEnabled = (id, data, options) =>
  axios.patch(getEndpointUrl('userSetAPIEnabled', { id }), data, options)
export const useSetUserAPIEnabled = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => setUserAPIEnabled(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(useUsersFetcher.queryKey(), (current) =>
          current
            ? {
                ...current,
                data: current.data.map((user) =>
                  user.id === id ? data : user,
                ),
              }
            : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useUsersFetcher.queryKey())
      },
    },
  )
}

export const getCurrentUser = (options) =>
  axios.get(`${getEndpointUrl('currentUser')}.json`, options)
export const useCurrentUserFetcher = makeFetcher('currentUser', getCurrentUser)

export const updateCurrentUser = (data, options) =>
  axios.patch(`${getEndpointUrl('currentUser')}.json`, data, options)
export const useUpdateCurrentUser = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => updateCurrentUser(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(useCurrentUserFetcher.queryKey(), data)
      },
      onSettled: () => {
        queryClient.invalidateQueries(useCurrentUserFetcher.queryKey())
      },
    },
  )
}

export const removeUserFromOrganization = (id, options) =>
  axios.delete(getEndpointUrl('userRemoveFromOrganization', { id }), options)
export const useRemoveUserFromOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => removeUserFromOrganization(id).then((res) => res.data),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData(useUsersFetcher.queryKey(), (current) =>
          current
            ? {
                ...current,
                data: current.data.filter((user) => user.id !== id),
              }
            : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useUsersFetcher.queryKey())
      },
    },
  )
}

export const getAnnouncements = (options) =>
  axios.get(getEndpointUrl('announcements'), options)
export const useAnnouncementsFetcher = makeCollectionFetcher(
  'announcements',
  getAnnouncements,
)

export const dismissNowAnnouncements = (data, options) =>
  axios.post(getEndpointUrl('announcementsDismissNow'), data, options)
export const useDismissNowAnnouncements = () =>
  useMutation(({ data } = {}) =>
    dismissNowAnnouncements(data).then((res) => res.data),
  )

export const createTwoFactorAuth = (data, options) =>
  axios.post(getEndpointUrl('twoFactorAuths'), data, options)
export const useCreateTwoFactorAuth = () =>
  useMutation(({ data }) => createTwoFactorAuth(data).then((res) => res.data))

export const disableTwoFactorAuth = (options) =>
  axios.delete(getEndpointUrl('twoFactorAuthsDisable'), options)
export const useDisableTwoFactorAuth = () =>
  useMutation((options) =>
    disableTwoFactorAuth(options).then((res) => res.data),
  )

export const getAssistAssessments = (options) =>
  axios.get(getEndpointUrl('assistAssessments'), options)
export const useAssistAssessmentsFetcher = makeCollectionFetcher(
  'assistAssessments',
  getAssistAssessments,
)

export const createAssistAssessments = (data, options) =>
  axios.post(getEndpointUrl('assistAssessments'), data, options)
export const useCreateAssistAssessment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createAssistAssessments(data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAssessmentsFetcher.queryKey())
      },
    },
  )
}

export const updateAssistAssessment = (id, data, options) =>
  axios.patch(getEndpointUrl('assistAssessment', { id }), data, options)
export const useUpdateAssistAssessment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateAssistAssessment(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          useAssistAssessmentsFetcher.queryKey(),
          (current) =>
            current
              ? {
                  ...current,
                  data: current.data.map((element) =>
                    element.id === id ? data : element,
                  ),
                }
              : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAssessmentsFetcher.queryKey())
      },
    },
  )
}

export const getAssistAnswerFiles = (options) =>
  axios.get(getEndpointUrl('assistAnswerFiles'), options)
export const useAssistAnswerFilesFetcher = makeCollectionFetcher(
  'assistAnswerFiles',
  getAssistAnswerFiles,
)

export const createAssistAnswerFile = (data, options) =>
  axios.post(getEndpointUrl('assistAnswerFiles'), data, options)
export const useCreateAssistAnswerFile = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createAssistAnswerFile(data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAnswerFilesFetcher.queryKey())
      },
    },
  )
}

export const deleteAssistAnswerFile = (id, options) =>
  axios.delete(getEndpointUrl('assistAnswerFile', { id }), options)
export const useDeleteAssistAnswerFile = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteAssistAnswerFile(id).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAnswerFilesFetcher.queryKey())
      },
    },
  )
}

export const selectAssistOrganization = (data, options) =>
  axios.post(getEndpointUrl('assistOrganizationsSelect'), data, options)
export const useSelectAssistOrganization = () =>
  useMutation(({ data }) =>
    selectAssistOrganization(data).then((res) => res.data),
  )

export const getOrganizationSSO = (organizationId, options) =>
  axios.get(getEndpointUrl('organizationSSO', { organizationId }), options)

export const getAssistQuestionSets = (options) =>
  axios.get(getEndpointUrl('assistQuestionSets'), options)
export const useAssistQuestionSetsFetcher = makeFetcher(
  'assistQuestionSets',
  getAssistQuestionSets,
)

export const getAssistQuestionSet = (id, options) =>
  axios.get(getEndpointUrl('assistQuestionSet', { id }), options)
export const useAssistQuestionSetFetcher = makeFetcher(
  'assistQuestionSet',
  ({ id, ...rest }) => getAssistQuestionSet(id, rest),
)

export const createAssistQuestionSet = (data, options) =>
  axios.post(getEndpointUrl('assistQuestionSets'), data, options)
export const useCreateAssistQuestionSet = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createAssistQuestionSet(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionSetsFetcher.queryKey()[0],
          },
          (current) => (current ? [...current, data] : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useAssistQuestionSetsFetcher.queryKey())
      },
    },
  )
}

export const updateAssistQuestionSet = (id, data, options) =>
  axios.patch(getEndpointUrl('assistQuestionSet', { id }), data, options)
export const useUpdateAssistQuestionSet = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateAssistQuestionSet(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          data,
        )
      },
      onSettled: (data, error, { id }) => {
        queryClient.invalidateQueries(useAssistQuestionSetFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const deleteAssistQuestionSet = (id, options) =>
  axios.delete(getEndpointUrl('assistQuestionSet', { id }), options)
export const useDeleteAssistQuestionSet = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteAssistQuestionSet(id).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionSetsFetcher.queryKey()[0],
          },
          (current) => (current ? current.filter((e) => e.id !== id) : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useAssistQuestionSetsFetcher.queryKey())
      },
    },
  )
}

export const getAssistQuestionSetStatus = (id, options) =>
  axios.get(getEndpointUrl('assistQuestionSetStatus', { id }), options)
export const useAssistQuestionSetStatusFetcher = makeFetcher(
  'assistQuestionSetStatus',
  ({ id, ...rest }) => getAssistQuestionSetStatus(id, rest),
)

export const addAnswerSetToAssistQuestionSetAnswerSets = (id, data, options) =>
  axios.post(
    getEndpointUrl('assistQuestionSetAnswerSets', { id }),
    data,
    options,
  )
export const useAddAnswerSetToAssistQuestionSetAnswerSets = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      addAnswerSetToAssistQuestionSetAnswerSets(id, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (_, { id, data }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          (current) =>
            current
              ? {
                  ...current,
                  answer_sets: [
                    ...current.answer_sets,
                    data.question_set_answer_set.assist_answer_set_id,
                  ],
                }
              : undefined,
        )
      },
      onSettled: (data, error, { id }) => {
        queryClient.invalidateQueries(useAssistQuestionSetFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const deleteAnswerSetFromAssistQuestionSetAnswerSets = (id, options) =>
  axios.delete(getEndpointUrl('assistQuestionSetAnswerSets', { id }), options)
export const useDeleteAnswerSetFromAssistQuestionSetAnswerSets = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...rest }) =>
      deleteAnswerSetFromAssistQuestionSetAnswerSets(id, rest).then(
        (res) => res.data,
      ),
    {
      onSuccess: (_, { id, data }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
              query.queryKey[1].id === id,
          },
          (current) =>
            current
              ? {
                  ...current,
                  answer_sets: current.answer_sets.filter(
                    (e) =>
                      e !== data.question_set_answer_set.assist_answer_set_id,
                  ),
                }
              : undefined,
        )
      },
      onSettled: (data, error, { id }) => {
        queryClient.invalidateQueries(useAssistQuestionSetFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useAssistQuestionSetFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        })
      },
    },
  )
}

export const getAssistQuestionSetScorerTypes = (options) =>
  axios.get(getEndpointUrl('assistQuestionSetScorerTypes'), options)
export const useAssistQuestionSetScorerTypesFetcher = makeFetcher(
  'assistQuestionSetScorerTypes',
  getAssistQuestionSetScorerTypes,
)

export const getAssistQuestionSetScorerModels = (options) =>
  axios.get(getEndpointUrl('assistQuestionSetScorerModels'), options)
export const useAssistQuestionSetScorerModelsFetcher = makeFetcher(
  'assistQuestionSetScorerModels',
  getAssistQuestionSetScorerModels,
)

export const getAssistAnswerSets = (options) =>
  axios.get(getEndpointUrl('assistAnswerSets'), options)
export const useAssistAnswerSetsFetcher = makeFetcher(
  'assistAnswerSets',
  getAssistAnswerSets,
)

export const createAssistAnswerSet = (data, options) =>
  axios.post(getEndpointUrl('assistAnswerSets'), data, options)
export const useCreateAssistAnswerSet = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createAssistAnswerSet(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistAnswerSetsFetcher.queryKey()[0],
          },
          (current) => (current ? [...current, data] : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAnswerSetsFetcher.queryKey())
      },
    },
  )
}

export const deleteAssistAnswerSet = (id, options) =>
  axios.delete(getEndpointUrl('assistAnswerSet', { id }), options)
export const useDeleteAssistAnswerSet = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => deleteAssistAnswerSet(id).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistAnswerSetsFetcher.queryKey()[0],
          },
          (current) => (current ? current.filter((e) => e.id !== id) : current),
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useAssistAnswerSetsFetcher.queryKey())
      },
    },
  )
}

export const getAssistQuestion = (id, options) =>
  axios.get(getEndpointUrl('assistQuestion', { id }), options)
export const useAssistQuestionFetcher = makeFetcher(
  'assistQuestion',
  ({ id, ...rest }) => getAssistQuestion(id, rest),
)

export const searchAssistQuestion = (id, options) =>
  axios.get(getEndpointUrl('assistQuestionSearch', { id }), options)
export const useAssistQuestionAdvancedFetcher = makeFetcher(
  'assistQuestionSearch',
  ({ id, ...rest }) => searchAssistQuestion(id, rest),
)

export const createAssistQuestionAnswer = (data, options) =>
  axios.post(getEndpointUrl('assistQuestionAnswers'), data, options)
export const useCreateAssistQuestionAnswer = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => createAssistQuestionAnswer(data).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionFetcher.queryKey()[0] &&
              query.queryKey[1].id === data.assist_question_id,
          },
          (current) => ({
            ...current,
            question_answers: current.question_answers.map((e) => {
              if (e.id === data.id) {
                return data
              }
              // If this request is for setting is_selected_answer, reset is_selected_answer of other answers
              if (variables.data?.question_answer?.is_selected_answer) {
                return {
                  ...e,
                  is_selected_answer: false,
                }
              }
              return e
            }),
          }),
        )
      },
      onSettled: (data) => {
        queryClient.invalidateQueries(useAssistQuestionFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useAssistQuestionFetcher.queryKey()[0] &&
            query.queryKey[1].id === data.assist_question_id,
        })
        queryClient.invalidateQueries(
          useAssistQuestionAdvancedFetcher.queryKey(),
          {
            predicate: (query) =>
              query.queryKey[0] ===
                useAssistQuestionAdvancedFetcher.queryKey()[0] &&
              query.queryKey[1].id === data.assist_question_id,
          },
        )
        queryClient.invalidateQueries(useAssistQuestionSetFetcher.queryKey())
      },
    },
  )
}

export const updateAssistQuestionAnswer = (id, data, options) =>
  axios.patch(getEndpointUrl('assistQuestionAnswer', { id }), data, options)
export const useUpdateAssistQuestionAnswer = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) =>
      updateAssistQuestionAnswer(id, data).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === useAssistQuestionFetcher.queryKey()[0] &&
              query.queryKey[1].id === data.assist_question_id,
          },
          (current) => ({
            ...current,
            question_answers: current.question_answers.map((e) => {
              if (e.id === data.id) {
                return data
              }
              // If this request is for setting is_selected_answer, reset is_selected_answer of other answers
              if (variables.data?.question_answer?.is_selected_answer) {
                return {
                  ...e,
                  is_selected_answer: false,
                }
              }
              return e
            }),
          }),
        )
      },
      onSettled: (data) => {
        queryClient.invalidateQueries(useAssistQuestionFetcher.queryKey(), {
          predicate: (query) =>
            query.queryKey[0] === useAssistQuestionFetcher.queryKey()[0] &&
            query.queryKey[1].id === data.assist_question_id,
        })
        queryClient.invalidateQueries(
          useAssistQuestionAdvancedFetcher.queryKey(),
          {
            predicate: (query) =>
              query.queryKey[0] ===
                useAssistQuestionAdvancedFetcher.queryKey()[0] &&
              query.queryKey[1].id === data.assist_question_id,
          },
        )
        queryClient.invalidateQueries(useAssistQuestionSetFetcher.queryKey())
      },
    },
  )
}

export const updateOrganizationSSO = (organizationId, data, options) =>
  axios.patch(
    getEndpointUrl('organizationSSO', { organizationId }),
    data,
    options,
  )

export const getOrganizationClientCustomers = (organizationId, options) =>
  axios.get(
    getEndpointUrl('organizationClientCustomers', { organizationId }),
    options,
  )
export const useOrganizationClientCustomersFetcher = makeFetcher(
  'organizationClientCustomers',
  ({ organizationId, ...options }) =>
    getOrganizationClientCustomers(organizationId, options),
)

export const updateCurrentOrganization = (data, options) =>
  axios.patch(`${getEndpointUrl('currentOrganization')}.json`, data, options)
export const useUpdateCurrentOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data }) => updateCurrentOrganization(data).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(
          useCurrentOrganizationFetcher.queryKey(),
          (current) => pickNewData(current, data),
        )
      },
      onError: (error) => {
        if (
          error.response.status === 403 &&
          error.response.data.message === 'enforce_mfa'
        ) {
          window.location.href = getPageUrl('usersTwoFactorAuths')
        } else {
          queryClient.invalidateQueries(
            useCurrentOrganizationFetcher.queryKey(),
          )
        }
      },
    },
  )
}

export const getUserSubscriptions = (userId) =>
  axios.get(
    getEndpointUrl('userSubscriptions', { user_id: userId }, undefined, 'json'),
  )
export const useUserSubscriptionsFetcher = makeFetcher(
  'userSubscriptions',
  ({ userId, ...options }) => getUserSubscriptions(userId, options),
)

export const createUserSubscription = (userId, data, options) =>
  axios.post(
    getEndpointUrl('userSubscriptions', { user_id: userId }),
    data,
    options,
  )
export const useCreateUserSubscription = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId, data }) =>
      createUserSubscription(userId, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useUserSubscriptionsFetcher.queryKey())
      },
    },
  )
}

export const subscribeUserSubscription = (userId, id, data, options) =>
  axios.put(
    getEndpointUrl('subscribeUserSubscription', { user_id: userId, id }),
    data,
    options,
  )
export const useSubscribeUserSubscription = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId, id, data }) =>
      subscribeUserSubscription(userId, id, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useUserSubscriptionsFetcher.queryKey())
      },
    },
  )
}

export const unsubscribeUserSubscription = (userId, id, data, options) =>
  axios.put(
    getEndpointUrl('unsubscribeUserSubscription', { user_id: userId, id }),
    data,
    options,
  )
export const useUnsubscribeUserSubscription = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId, id, data }) =>
      unsubscribeUserSubscription(userId, id, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useUserSubscriptionsFetcher.queryKey())
      },
    },
  )
}

export const subscribeUserSubscriptionEmail = (userId, id, data, options) =>
  axios.put(
    getEndpointUrl('subscribeUserSubscriptionEmail', {
      user_id: userId,
      id,
    }),
    data,
    options,
  )
export const useSubscribeUserSubscriptionEmail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId, id, data }) =>
      subscribeUserSubscriptionEmail(userId, id, data).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useUserSubscriptionsFetcher.queryKey())
      },
    },
  )
}

export const unsubscribeUserSubscriptionEmail = (userId, id, data, options) =>
  axios.put(
    getEndpointUrl('unsubscribeUserSubscriptionEmail', {
      user_id: userId,
      id,
    }),
    data,
    options,
  )
export const useUnsubscribeUserSubscriptionEmail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId, id, data }) =>
      unsubscribeUserSubscriptionEmail(userId, id, data).then(
        (res) => res.data,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useUserSubscriptionsFetcher.queryKey())
      },
    },
  )
}

export const updateUserOrgRole = (id, data, options) =>
  axios.patch(getEndpointUrl('userOrgRoleSet', { id }), data, options)
export const useUpdateUserOrgRole = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => updateUserOrgRole(id, data).then((res) => res.data),
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueriesData(useUsersFetcher.queryKey(), (current) =>
          current
            ? {
                ...current,
                data: current.data.map((user) =>
                  user.id === id ? data : user,
                ),
              }
            : undefined,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(useUsersFetcher.queryKey())
      },
    },
  )
}

export const inviteUser = (data, options) =>
  axios.post(getEndpointUrl('userInvitation'), data, options)
export const useInviteUser = () => {
  const queryClient = useQueryClient()
  return useMutation(({ data }) => inviteUser(data).then((res) => res.data), {
    onSettled: () => {
      queryClient.invalidateQueries(useUsersFetcher.queryKey())
    },
  })
}

export const getUserPreferences = (keys, options) =>
  axios.get(getEndpointUrl('userPreferences'), { params: { keys } }, options)

export const updateUserPreferences = (data, options) =>
  axios.patch(getEndpointUrl('userPreferences'), data, options)
export const useUpdateUserPreferences = () =>
  useMutation(({ data }) => updateUserPreferences(data).then((res) => res.data))

export const autoCompleteGlobalOrg = (options) =>
  axios.get(getEndpointUrl('globorgAutocomplete'), options)

export const getGlobalOrg = (id, options) =>
  axios.get(getEndpointUrl('globorgOrg', { id }), options)
export const useGlobalOrgFetcher = makeFetcher(
  'globorgOrg',
  ({ id, ...rest }) => getGlobalOrg(id, rest),
)

export const getGlobalOrgIntegrationMetrics = (id, options) =>
  axios.get(getEndpointUrl('globorgOrgIntegrationMetrics', { id }), options)
export const useGlobalOrgIntegrationMetricsFetcher = makeFetcher(
  'globorgOrgIntegrationMetrics',
  ({ globorgOrgId, ...rest }) =>
    getGlobalOrgIntegrationMetrics(globorgOrgId, rest),
)

export const getGlobalOrgIntegrationMetricsRiskReconIssues = (id, options) =>
  axios.get(
    getEndpointUrl('globorgOrgIntegrationMetricsRiskReconIssues', { id }),
    options,
  )
export const useGlobalOrgIntegrationMetricsRiskReconIssuesFetcher = makeFetcher(
  'globorgOrgIntegrationMetricsRiskReconIssues',
  ({ globorgOrgId, ...rest }) =>
    getGlobalOrgIntegrationMetricsRiskReconIssues(globorgOrgId, rest),
)

// export const getLibraryOrgReceivedOrgs = (options) =>
//   axios.get(getEndpointUrl('libraryOrgReceivedOrgs'), options)
// export const useLibraryOrgReceivedOrgsFetcher = makeCollectionFetcher(
//   'libraryOrgReceivedOrgs',
//   getLibraryOrgReceivedOrgs,
// )

export const getLibraryManageListings = (options) =>
  axios.get(getEndpointUrl('libraryManageListings'), options)
export const useLibraryManageListingsFetcher = makeFetcher(
  'libraryManageListings',
  (options) => getLibraryManageListings(options),
)

export const getLibraryManageListing = (id, options) =>
  axios.get(getEndpointUrl('libraryManageListing', { id }), options)
export const useLibraryManageListingFetcher = makeFetcher(
  'libraryManageListing',
  ({ id, ...options }) => getLibraryManageListing(id, options),
)

export const updateLibraryManageListing = (id, data, options) =>
  axios.patch(getEndpointUrl('libraryManageListing', { id }), data, options)
export const useUpdateLibraryManageListing = () =>
  useMutation(({ id, data }) =>
    updateLibraryManageListing(id, data).then((res) => res.data),
  )

export const getLibraryManageShares = (options) =>
  axios.get(getEndpointUrl('libraryManageShares'), options)
export const useLibraryManageSharesFetcher = makeFetcher(
  'libraryApiManageShares',
  getLibraryManageShares,
)

export const createLibraryManageShare = (data, options) =>
  axios.post(getEndpointUrl('libraryManageShares'), data, options)
export const useCreateLibraryManageShare = () =>
  useMutation(({ data }) =>
    createLibraryManageShare(data).then((res) => res.data),
  )

export const updateLibraryManageShare = (id, data, options) =>
  axios.patch(getEndpointUrl('libraryManageShare', { id }), data, options)
export const useUpdateLibraryManageShare = () =>
  useMutation(({ id, data }) =>
    updateLibraryManageShare(id, data).then((res) => res.data),
  )

export const getLibraryManageShareRequests = (options) =>
  axios.get(getEndpointUrl('libraryManageShareRequests'), options)
export const useLibraryManageShareRequestsFetcher = makeFetcher(
  'libraryManageShareRequests',
  getLibraryManageShareRequests,
)

export const getLibraryManageSharesByClient = (options) =>
  axios.get(getEndpointUrl('libraryManageSharesByClient'), options)
export const useLibraryManageSharesByClientFetcher = makeFetcher(
  'libraryManageSharesByClient',
  getLibraryManageSharesByClient,
)

export const updateLibraryManageSharesByClient = (data, options) =>
  axios.patch(getEndpointUrl('libraryManageSharesClientUpdate'), data, options)
export const useUpdateLibraryManageSharesByClient = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ data, ...options }) =>
      updateLibraryManageSharesByClient(data, options).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          useLibraryManageShareRequestsFetcher.queryKey(),
        )
        queryClient.invalidateQueries(
          useLibraryManageSharesByClientFetcher.queryKey(),
        )
      },
    },
  )
}

export const getLibraryProfiles = (options) =>
  axios.get(getEndpointUrl('libraryProfiles'), options)
export const useLibraryProfilesFetcher = makeCollectionFetcher(
  'libraryProfiles',
  getLibraryProfiles,
)

export const getLibraryManageProfile = (id, options) =>
  axios.get(getEndpointUrl('libraryManageProfile', { id }), options)
export const useLibraryManageProfileFetcher = makeFetcher(
  'libraryManageProfile',
  ({ id, ...options }) => getLibraryManageProfile(id, options),
)

export const getLibraryListings = (options) =>
  axios.get(getEndpointUrl('libraryListings'), options)
export const useLibraryListingsFetcher = makeCollectionFetcher(
  'libraryListings',
  getLibraryListings,
)

export const getLibraryListingDetails = (id, options) =>
  axios.get(getEndpointUrl('libraryListing', { id }), options)
export const useLibraryListingDetailsFetcher = makeFetcher(
  'libraryListing',
  ({ id, ...options }) => getLibraryListingDetails(id, options),
)

export const getLibraryProfileVendor = (id, options) =>
  axios.get(getEndpointUrl('libraryProfileVendor', { id }), options)
export const useLibraryProfileVendorFetcher = makeFetcher(
  'libraryProfileVendor',
  ({ id, ...options }) => getLibraryProfileVendor(id, options),
)

export const convertLibraryProfile = (id, options) =>
  axios.get(getEndpointUrl('libraryProfileConvert', { id }), options)
export const useConvertLibraryProfile = () =>
  useMutation(({ id, ...rest }) =>
    convertLibraryProfile(id, rest).then((res) => res.data),
  )

export const getLibraryShare = (id, options) =>
  axios.get(getEndpointUrl('libraryShare', { id }), options)
export const useLibraryShareFetcher = makeFetcher(
  'libraryShare',
  ({ id, ...options }) => getLibraryShare(id, options),
)

export const requestShareToLibraryListing = (id, options) =>
  axios.get(getEndpointUrl('libraryListingRequestShare', { id }), options)
export const useRequestShareToLibraryListing = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...rest }) =>
      requestShareToLibraryListing(id, rest).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(useLibraryListingsFetcher.queryKey())
      },
    },
  )
}

export const getPromotionsForPlacement = (options) =>
  axios.get(getEndpointUrl('promotionsForPlacement'), options)
export const usePromotionsForPlacementFetcher = makeFetcher(
  'promotionsForPlacement',
  getPromotionsForPlacement,
)

export const updatePromotionClickCount = (id, options) =>
  axios.put(getEndpointUrl('promotionIncrementClickCount', { id }), options)
export const useUpdatePromotionClickCount = () =>
  useMutation(({ id }) => updatePromotionClickCount(id).then((res) => res.data))

export const initiateCallToAction = (id, options) =>
  axios.put(getEndpointUrl('promotionProcessCallToAction', { id }), options)
export const useInitiateCallToAction = () =>
  useMutation(({ id }) => initiateCallToAction(id).then((res) => res.data))

export const getIndustries = (options) =>
  axios.get(getEndpointUrl('industries'), options)
export const useIndustriesFetcher = makeFetcher('industries', getIndustries)

export const scopePermissions = (options) =>
  axios.get(getEndpointUrl('scopePermissions'), options)
export const useScopePermissionsFetcher = makeFetcher(
  'scopePermissions',
  scopePermissions,
)

// Utilities

const invalidateClientAssessmentTemplateQueries = (queryClient, id) => {
  queryClient.invalidateQueries(useClientAssessmentTemplateFetcher.queryKey(), {
    predicate: (query) =>
      query.queryKey[0] === useClientAssessmentTemplateFetcher.queryKey()[0] &&
      query.queryKey[1].id === id,
  })
}

const setClientAssessmentTemplateQueriesData = (queryClient, id, data) => {
  queryClient.setQueriesData(
    {
      predicate: (query) =>
        query.queryKey[0] ===
          useClientAssessmentTemplateFetcher.queryKey()[0] &&
        query.queryKey[1].id === id,
    },
    data,
  )
}

const replaceClientAssessmentTemplateQueriesData = (queryClient, data) =>
  setClientAssessmentTemplateQueriesData(queryClient, data.id, data)

const setElementToClientAssessmentTemplateQueriesData = (
  queryClient,
  element,
) =>
  setClientAssessmentTemplateQueriesData(
    queryClient,
    element.assessment_template_id,
    (current) => ({
      ...current,
      elements: current.elements.map((e) => {
        if (e.id === element.id) {
          return {
            ...element,
            key: element.key || e.key,
            natural_key: element.natural_key || e.natural_key,
          }
        }
        return e
      }),
    }),
  )

const pickNewData = (before, after) => {
  const updatedAtA = toDate(before.updated_at)
  const updatedAtB = toDate(after.updated_at)
  return !updatedAtA || !updatedAtB || !isBefore(updatedAtB, updatedAtA)
    ? after
    : before
}
