/*
  When all you need is a simple pop-up with a few basic fields in it.
  Usage:
    const [openFormDialog, closeFormDialog] = useSimpleFormDialog('Title Name')
    ...
    // use any props you'd send to FormField
    const fields = [
      {
        name: 'field_1',
        label: 'New Field Name',
        placeholder: 'Enter name',
        validType: 'string',
      },
      {
        name: 'field_2',
        label: 'Should be a drop-down',
        type: 'select',
        options: [
          { value: 0, label: 'First option'},
          { value: 1, label: 'Second option'}
          { value: 2, label: 'Third option'}
        ],
        initial: 1,           // initial value for Formik
        validType: 'number',  // yup type name as a string
      },
    ]

    openFormDialog({
      fields,
      onSubmit: (formData) => {
        console.log('formDialog submit:', formData)
        closeFormDialog()
      },
*/
import React from 'react'
import FormField from 'components-v2/molecules/FormField'
import * as yup from 'yup'
import useFormDialog from './useFormDialog'

export default function useSimpleFormDialog(title) {
  const [openModal, closeModal] = useFormDialog()

  const funcs = React.useMemo(() => {
    const fieldSet = []
    const initialValues = {}
    const validations = {}

    const addField = ({ initial, validType, ...rest }) => {
      initialValues[rest.name] = initial
      if (validType) validations[rest.name] = yup[validType]()
      fieldSet.push(<FormField key={rest.name} {...rest} />)
    }

    const openFormModal = ({ children, fields = [], ...props }) => {
      fields.forEach((field) => {
        addField(field)
      })
      const formConfig = {
        initialValues,
        validationSchema: yup.object().shape(validations),
      }
      openModal({
        title, // : formTitle,
        formConfig,
        children: <>{fieldSet.concat(children)}</>,
        onClose: closeFormModal,
        onCancel: closeFormModal,
        ...props,
      })
    }

    const closeFormModal = () => {
      // clear these out so opening again
      // doesn't duplicate them
      fieldSet.splice(0, fieldSet.length)
      closeModal()
    }

    return [openFormModal, closeFormModal, addField]
  }, [title])

  return funcs
}
