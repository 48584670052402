import map from 'lodash/map'
import { exportClientIssuesToCSV } from 'lib/export'
import { getIssueStatusOptions } from 'lib/issue'
import {
  IssuePriorityOptions,
  IssueResolutionOptions,
  LabelTypes,
  RiskTierOptions,
} from 'constants/index'

const statusFilterOptions = map(
  getIssueStatusOptions(false),
  (option, key) => ({ value: key, label: option.label }),
)

export const getFilterConfigList = (
  users,
  categories,
  vendorTags,
  isSingleVendor,
) => {
  const filterConfigList = [
    {
      name: 'statuses',
      title: 'Status',
      options: statusFilterOptions,
      selectAllVisible: true,
    },
  ]
  if (categories.length > 0) {
    filterConfigList.push({
      name: 'categories',
      title: 'Category',
      options: categories.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
    })
  }
  if (!isSingleVendor && vendorTags.length > 0) {
    filterConfigList.push({
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  if (users.length > 0) {
    filterConfigList.push({
      name: 'clientAssignees',
      title: 'Assignee(s)',
      options: users.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
      otherVisible: true,
      otherLabel: 'Unassigned',
    })
  }
  filterConfigList.push({
    name: 'resolutions',
    title: 'Resolution',
    options: IssueResolutionOptions,
    selectAllVisible: true,
  })
  filterConfigList.push({
    name: 'priorities',
    title: 'Priority',
    options: IssuePriorityOptions,
    selectAllVisible: true,
  })
  if (!isSingleVendor) {
    filterConfigList.push({
      name: 'riskTiers',
      title: `${LabelTypes.VENDOR} Risk Tier`,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      selectAllVisible: true,
    })
  }
  return filterConfigList
}

export function exportToCSV(
  {
    searchText,
    sortField,
    sortOrder,
    statuses,
    categories,
    resolutions,
    clientAssignees,
    unassigned,
    vendorTags,
    vendorTagsAnyMatch,
    priorities,
    riskTiers,
  },
  vendorId,
  assessmentId,
) {
  exportClientIssuesToCSV({
    searchQuery: searchText,
    orderedBy: sortField,
    direction: sortOrder,
    statuses,
    categories,
    resolutions,
    clientAssignees,
    unassigned,
    priorities,
    riskTiers,
    vendorTags,
    vendorTagsAnyMatch,
    vendorId,
    assessmentId,
  })
}
