import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { toDate } from 'utils/date'
import ExpirationReminderFormField from './ExpirationReminderFormField'
import { OrText } from './styles'

const validationSchema = yup.object().shape(
  {
    attachment: yup.mixed().when('existing_document_url', {
      is: (value) => !value || !value.trim(),
      then: yup
        .mixed()
        .nullable()
        .required('Please attach a file or set an existing document url'),
    }),
    existing_document_url: yup.string().when('attachment', {
      is: (value) => !value,
      then: yup
        .string()
        .trim()
        .required('Please attach a file or set an existing document url'),
    }),
  },
  [['attachment', 'existing_document_url']],
)

const VendorAttachmentModal = ({ vendorAttachment, ...rest }) => {
  const formConfig = React.useMemo(
    () => ({
      initialValues: {
        attachment: vendorAttachment?.attachment,
        existing_document_url: vendorAttachment?.existing_document_url,
        expire_at: toDate(vendorAttachment?.expire_at),
        reminder_days: vendorAttachment ? vendorAttachment.reminder_days : 30,
        comment: vendorAttachment?.comment,
      },
      validationSchema,
    }),
    [vendorAttachment],
  )

  return (
    <FormDialog {...rest} formConfig={formConfig}>
      {({ values }) => (
        <>
          <FormField
            name="attachment"
            label="File"
            type={FormField.types.FILE_INPUT}
            disabled={!values.attachment && !!values.existing_document_url}
            horizontal
          />
          <OrText>or</OrText>
          <FormField
            name="existing_document_url"
            label="Link to Existing Document"
            disabled={!!values.attachment}
            horizontal
          />
          <FormField
            name="expire_at"
            label="Expiration Date"
            type={FormField.types.DATE}
            horizontal
          />
          <FormField
            name="reminder_days"
            label="Expiration Reminder"
            component={ExpirationReminderFormField}
            horizontal
          />
          <FormField
            name="comment"
            label="Comment"
            type={FormField.types.TEXTAREA}
            controlProps={{
              rows: 3,
            }}
            horizontal
          />
        </>
      )}
    </FormDialog>
  )
}

VendorAttachmentModal.propTypes = {
  vendorAttachment: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default VendorAttachmentModal
