import React from 'react'
import ModalManager from 'lib/modal'

export default function useModal(modalComponent) {
  const functions = React.useMemo(
    () => ModalManager.getModalFunctions(modalComponent),
    [modalComponent],
  )

  // Make sure to close the modal when parent component is destroyed or "modalComponent" is changed
  React.useEffect(
    () => () => {
      functions[1]()
    },
    [functions],
  )

  return functions
}
