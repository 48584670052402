import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import FormField from 'components-v2/molecules/FormField'
import { Row, Col } from 'components-v2/organisms/Layout'
import formatBytes from 'utils/format_bytes'
import { useFormField } from 'hooks'
import ExpirationReminderFormField from './ExpirationReminderFormField'
import { Wrapper, Inner, FileIcon, DeleteIconButton, Text } from './styles'

const theme = {
  formFieldHorizontalLabelWidth: '140px',
  vGutter: 12,
}

const FileFieldGroup = ({ name, disabled, onDelete }) => {
  const { value } = useFormField(name)
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <FileIcon />
        <Inner>
          <Row>
            <Col sm={6}>
              <Text>File Name ({value.file.name})</Text>
            </Col>
            <Col sm={6}>
              <Text>Size ({formatBytes(value.file.size)})</Text>
            </Col>
            <Col sm={6}>
              <FormField
                name={`${name}.expire_at`}
                label="Expiration Date"
                type={FormField.types.DATE}
                preserveHelpTextSpace={false}
                size="small"
                horizontal
                disabled={disabled}
              />
            </Col>
            <Col sm={6}>
              <FormField
                name={`${name}.reminder_days`}
                label="Expiration Reminder"
                component={ExpirationReminderFormField}
                preserveHelpTextSpace={false}
                size="small"
                horizontal
                disabled={disabled}
              />
            </Col>
            <Col sm={12}>
              <FormField
                name={`${name}.comment`}
                label="Comment"
                type={FormField.types.TEXTAREA}
                controlProps={{
                  rows: 1,
                }}
                preserveHelpTextSpace={false}
                size="small"
                horizontal
                disabled={disabled}
              />
            </Col>
          </Row>
        </Inner>
        <DeleteIconButton disabled={disabled} onClick={onDelete} />
      </Wrapper>
    </ThemeProvider>
  )
}

FileFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
}

export default FileFieldGroup
