import React from 'react'
import PropTypes from 'prop-types'
import RDU from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import config from 'config'
import { useModal } from 'hooks'
import Icon from 'components-v2/atoms/Icon'
import { getCSRFToken } from 'setupCSRFToken'
import AttachFilesModal from '../AttachFilesModal'
import Layout from './Layout'

const rduClassNames = {
  dropzone: '',
  dropzoneActive: '',
  dropzoneReject: '',
}

const DropzoneUploader = ({
  buttonComponent,
  buttonContent,
  buttonColor,
  description,
  accept,
  maxFiles,
  maxSizeBytes,
  disabled,
  getUploadParams,
  confirmUpload,
  setTrigger,
  ...rest
}) => {
  const [openAttachFilesModal, closeAttachFilesModal] =
    useModal(AttachFilesModal)
  const dropzoneRef = React.useRef()

  // Inject CSRF Token Header
  const getUploadParamsWithTokenHeader = React.useMemo(() => {
    if (!getUploadParams) {
      return undefined
    }
    return (fileWithMeta) => {
      const params = getUploadParams(fileWithMeta)
      if (params) {
        params.headers = {
          ...(params.headers || {}),
          'X-CSRF-Token': getCSRFToken(),
        }
      }
      return params
    }
  }, [getUploadParams])

  const handleAttachButtonClick = React.useCallback(() => {
    const onSubmit = (files) => {
      if (confirmUpload) {
        confirmUpload(files).then((confirmed) => {
          if (confirmed) dropzoneRef.current.handleFiles(files)
          closeAttachFilesModal()
        })
      } else {
        dropzoneRef.current.handleFiles(files)
        closeAttachFilesModal()
      }
    }
    openAttachFilesModal({
      description,
      accept,
      maxSizeBytes,
      maxFiles,
      onSubmit,
    })
  }, [
    openAttachFilesModal,
    closeAttachFilesModal,
    description,
    accept,
    maxSizeBytes,
    maxFiles,
    confirmUpload,
  ])
  setTrigger(handleAttachButtonClick)

  const CustomLayout = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => (props) =>
      (
        <Layout
          {...props}
          buttonComponent={buttonComponent}
          buttonContent={buttonContent}
          buttonColor={buttonColor}
          buttonDisabled={disabled}
          onButtonClick={handleAttachButtonClick}
        />
      ),
    [
      buttonComponent,
      buttonContent,
      buttonColor,
      disabled,
      handleAttachButtonClick,
    ],
  )

  return (
    <RDU
      {...rest}
      ref={dropzoneRef}
      LayoutComponent={CustomLayout}
      accept={accept}
      maxFiles={maxFiles}
      maxSizeBytes={maxSizeBytes}
      disabled={disabled}
      getUploadParams={getUploadParamsWithTokenHeader}
      classNames={rduClassNames}
    />
  )
}

DropzoneUploader.propTypes = {
  buttonComponent: PropTypes.any,
  buttonContent: PropTypes.node,
  buttonColor: PropTypes.string,
  description: PropTypes.string,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  disabled: PropTypes.bool,
  getUploadParams: PropTypes.func,
  confirmUpload: PropTypes.func,
  setTrigger: PropTypes.func,
}

DropzoneUploader.defaultProps = {
  buttonContent: [
    <Icon key="icon" icon="fa fa-cloud-upload" />,
    'Attach file(s)',
  ],
  accept:
    '.pdf,.doc,.docx,.xls,.xlsx,.csv,.rtf,.zip,.mp3,.wma,.mpg,.flv,.avi,.jpg,.jpeg,.png,.gif,.ppt,.pptx',
  maxSizeBytes: config.maxAttachmentBytes,
  maxFiles: 20,
  disabled: false,
  setTrigger: () => {},
}

export default DropzoneUploader
