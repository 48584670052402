import React from 'react'
import ConfirmDialog from 'components-v2/organisms/ConfirmDialog'
import useModal from './useModal'

export default function useConfirm() {
  const functions = useModal(ConfirmDialog)
  return React.useCallback(
    (modalProps = {}, returnPromise = false) => {
      if (returnPromise) {
        return new Promise((resolve) => {
          functions[0]({
            ...modalProps,
            onConfirm: () => {
              functions[1]()
              resolve(true)
            },
            onCancel: () => {
              functions[1]()
              resolve(false)
            },
          })
        })
      }

      return functions[0]({
        ...modalProps,
        onConfirm: () => {
          // TODO: Check if there is real use case that we need to delay closing until an async action is completed
          Promise.resolve(modalProps.onConfirm && modalProps.onConfirm()).then(
            () => {
              // If the confirming promise is resolved, close the modal
              functions[1]()
            },
          )
        },
        onCancel: () => {
          functions[1]()
          if (modalProps.onCancel) {
            modalProps.onCancel()
          }
        },
      })
    },
    [functions],
  )
}
