import { useState, useCallback } from 'react'
import { useConfirm, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { getPageUrl } from 'utils/url'
import { unflatten } from 'utils/object'
import { useCreateTwoFactorAuth, useDisableTwoFactorAuth } from 'apis'

export default function useTwoFactorAuthMutations() {
  const confirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: create } = useCreateTwoFactorAuth()
  const { mutateAsync: disable } = useDisableTwoFactorAuth()
  const [registered, setRegistered] = useState(false)
  const [codes, setCodes] = useState([])

  const disableTwoFactorAuth = useCallback(() => {
    confirm({
      title: 'Please Confirm',
      body: 'Are you sure want to Disable Two-factor Authentication? This will clear all your two-factor authentication registrations and delete any backup codes.',
      onConfirm: () => {
        const loaderId = showLoader()
        disable()
          .then(() => {
            window.location.href = getPageUrl('user', undefined, {
              tab: 'security',
            })
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
          })
          .finally(() => {
            hideLoader(loaderId)
          })
      },
    })
  }, [confirm, disable, showLoader, hideLoader])

  const registerTwoFactorAuth = useCallback(
    async (formData, { setErrors }) => {
      const loaderId = showLoader()
      try {
        const res = await create({
          data: formData,
        })
        setRegistered(true)
        setCodes(res.codes)
      } catch (error) {
        if (error.response.status === 422) {
          setErrors(unflatten(error.response.data.errors))
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    },
    [create, showLoader, hideLoader],
  )

  return {
    codes,
    registered,
    disableTwoFactorAuth,
    registerTwoFactorAuth,
  }
}
