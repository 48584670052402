import React from 'react'
import PropTypes from 'prop-types'
import { Float } from '@headlessui-float/react'
import styled from 'styled-components'
import { Popover } from '@headlessui/react'
import FocusLock, { useFocusScope } from 'react-focus-lock'
import { fonts } from 'styles'

const Panel = styled(Popover.Panel)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
`

export const Card = styled.div`
  position: relative;
  min-width: 160px;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
`

const Title = styled.div`
  padding: 8px 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-family: ${fonts.SECONDARY};
  font-weight: bold;
  line-height: 1.1;
`

const Arrow = styled(Float.Arrow)`
  position: absolute;
  background: ${({ $hasTitle }) => ($hasTitle ? '#f7f7f7' : '#fff')};
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
`

const PanelContent = ({ title, children, renderProps }) => {
  const { autoFocus } = useFocusScope()

  React.useEffect(() => {
    // Add delay to make the panel's floating positioning completed before auto focusing
    setTimeout(() => {
      autoFocus()
    }, [100])
  }, [])

  return (
    <Card>
      {title && <Title>{title}</Title>}
      {typeof children === 'function' ? children(renderProps) : children}
    </Card>
  )
}

PanelContent.propTypes = {
  title: PropTypes.node,
  renderProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

const PopoverPanel = ({ title, arrow, children, ...rest }) => (
  <Float.Content {...rest}>
    <Panel>
      {(renderProps) => (
        <>
          {arrow && <Arrow $hasTitle={!!title} />}
          <FocusLock returnFocus autoFocus={false}>
            {/* Hack for an weird bug that cannot select text when focus lock is enabled. https://github.com/theKashey/react-focus-lock/issues/124#issuecomment-1140363516 */}
            <div tabIndex={-1}>
              <PanelContent title={title} renderProps={renderProps}>
                {children}
              </PanelContent>
            </div>
          </FocusLock>
        </>
      )}
    </Panel>
  </Float.Content>
)

PopoverPanel.propTypes = {
  title: PropTypes.node,
  arrow: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

PopoverPanel.defaultProps = {
  arrow: false,
}

export default PopoverPanel
