import { getPageUrl } from 'utils/url'
import { localTimeZoneOffsetInHours } from 'utils/date'
import { FilterTypes } from 'components-v2/molecules/FilterDropdownTray'
import { LabelTypes, RiskTierOptions, ExportFormatTypes } from 'constants/index'
import { RequestStatuses } from './constants'

export const getFilterConfigList = (vendorTags, requests, requesters) =>
  requests ? getRequestFilters(requesters) : getVendorFilters(vendorTags)

const getVendorFilters = (vendorTags) => {
  const filterConfigList = []
  if (vendorTags && vendorTags.length > 0) {
    filterConfigList.push({
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  return filterConfigList.concat([
    {
      name: 'riskTiers',
      title: `${LabelTypes.VENDOR} Risk Tier`,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      selectAllVisible: true,
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
    {
      name: 'nextReassessmentAt',
      title: 'Next Assessment',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ])
}

const getRequestFilters = (requesters = []) => {
  const filterConfigList = [
    {
      name: 'requestedBy',
      title: 'Requested By',
      options: requesters.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    },
    {
      name: 'status',
      title: 'Status',
      options: Object.entries(RequestStatuses).map(([k, v]) => ({
        value: k,
        label: v,
      })),
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ]
  return filterConfigList
}

export const getVendorsQueryParams = (
  { filter, includeArchived, ...rest },
  requests,
) => {
  const params = {
    ...rest,
    requests,
  }
  params.vendorTags = filter.vendorTags?.selected ?? []
  params.vendorTagsAnyMatch = filter.vendorTags?.anyMatch ?? true
  params.riskTiers = filter.riskTiers?.selected ?? []
  params.requestedBy = filter.requestedBy?.selected ?? []
  params.status = filter.status?.selected ?? []
  params.createdAtStart = filter.createdAt?.startDate
  params.createdAtEnd = filter.createdAt?.endDate
  params.nextReassessmentAtStart = filter.nextReassessmentAt?.startDate
  params.nextReassessmentAtEnd = filter.nextReassessmentAt?.endDate
  params.includeArchived =
    params.status.length > 0
      ? params.status.includes('declined')
      : includeArchived
  return params
}

export const getExportUrl = (
  format,
  {
    requests,
    includeArchived,
    searchText,
    vendorTags,
    vendorTagsAnyMatch,
    riskTiers,
    requestedBy,
    status,
    createdAtStart,
    createdAtEnd,
    nextReassessmentAtStart,
    nextReassessmentAtEnd,
  },
) =>
  getPageUrl(
    requests ? 'clientVendorRequestsExport' : 'clientVendorsExport',
    undefined,
    {
      requests,
      exclude_archived: !includeArchived,
      search_query: searchText,
      any: vendorTagsAnyMatch,
      with_vendor_tags: vendorTags.join(','),
      risk_tier_list: riskTiers,
      requested_by: requestedBy,
      status_list: status,
      create_start_date: createdAtStart,
      create_end_date: createdAtEnd,
      next_start_date: nextReassessmentAtStart,
      next_end_date: nextReassessmentAtEnd,
      tz: localTimeZoneOffsetInHours,
    },
    format,
  )

export const getCSVContactExportUrl = ({
  includeArchived,
  searchText,
  vendorTags,
  vendorTagsAnyMatch,
  riskTiers,
  createdAtStart,
  createdAtEnd,
  nextReassessmentAtStart,
  nextReassessmentAtEnd,
}) =>
  getPageUrl(
    'clientVendorsContactsExport',
    undefined,
    {
      exclude_archived: !includeArchived,
      search_query: searchText,
      any: vendorTagsAnyMatch,
      with_vendor_tags: vendorTags.join(','),
      risk_tier_list: riskTiers,
      create_start_date: createdAtStart,
      create_end_date: createdAtEnd,
      next_start_date: nextReassessmentAtStart,
      next_end_date: nextReassessmentAtEnd,
      tz: localTimeZoneOffsetInHours,
    },
    ExportFormatTypes.CSV,
  )
