import React from 'react'
import PropTypes from 'prop-types'
import SimpleDialog from '../../molecules/SimpleDialog'

const ConfirmDialog = ({
  title,
  body,
  confirmText,
  cancelText,
  confirmColor,
  cancelColor,
  showCancelButton,
  onConfirm,
  onCancel,
  ...rest
}) => {
  const buttons = React.useMemo(() => {
    const arr = [
      {
        color: confirmColor,
        onClick: onConfirm,
        children: confirmText,
      },
    ]
    if (showCancelButton) {
      arr.unshift({
        color: cancelColor,
        onClick: onCancel,
        children: cancelText,
      })
    }
    return arr
  }, [
    confirmColor,
    onConfirm,
    confirmText,
    showCancelButton,
    cancelColor,
    onCancel,
    cancelText,
  ])
  return (
    <SimpleDialog
      {...rest}
      title={title}
      buttons={buttons}
      className="dyn-lang"
      onClose={onCancel}
      {...rest}
    >
      {body}
    </SimpleDialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  cancelText: PropTypes.node,
  confirmText: PropTypes.node,
  cancelColor: PropTypes.string,
  confirmColor: PropTypes.string,
  showCancelButton: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

ConfirmDialog.defaultProps = {
  title: 'Confirm',
  body: 'Are you sure you wish to do this?',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  cancelColor: 'default',
  confirmColor: 'danger',
  showCancelButton: true,
}

export default React.memo(ConfirmDialog)
