import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Row, Col } from 'components-v2/organisms/Layout'
import AssessmentListingWidget from 'components/library/shared/AssessmentListingWidget'
import { getPageUrl } from 'utils/url'
import { LibraryShareStatusTypes } from 'constants/index'
import { theme, SubTitle } from './styles'

const View = ({ listings, onRequestAccess }) => {
  const assessmentListings = listings?.filter(
    (e) => e.listing_item_type === 'Assessment',
  )
  return (
    <ThemeProvider theme={theme}>
      <SubTitle>Assessments</SubTitle>
      <Row $equalHeight>
        {assessmentListings?.map((assessmentListing) => (
          <Col md={3} key={assessmentListing.id}>
            {assessmentListing.client_share?.status ===
            LibraryShareStatusTypes.ACTIVE ? (
              <AssessmentListingWidget
                name={assessmentListing.name}
                description={assessmentListing.description}
                updatedAt={assessmentListing.updated_at}
                buttonProps={{
                  children: 'View',
                  color: 'primary',
                  href: getPageUrl('libraryShare', {
                    id: assessmentListing.client_share?.id,
                  }),
                }}
              />
            ) : (
              <AssessmentListingWidget
                name={assessmentListing.name}
                description={assessmentListing.description}
                states={[{ icon: 'fa fa-lock', label: 'Locked' }]}
                updatedAt={assessmentListing.updated_at}
                buttonProps={{
                  children:
                    assessmentListing.client_share?.status ===
                    LibraryShareStatusTypes.REQUESTED
                      ? 'Requested Access'
                      : 'Request Access',
                  disabled:
                    assessmentListing.client_share?.status ===
                    LibraryShareStatusTypes.REQUESTED,
                  onClick: () => onRequestAccess(assessmentListing.id),
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </ThemeProvider>
  )
}

View.propTypes = {
  listings: PropTypes.array,
  onRequestAccess: PropTypes.func.isRequired,
}

export default View
